header.header {
  .top-header {
    background: $background;
    width: 100%;
    display: flex;

    nav {
      ul {
        display: flex;
        justify-content: flex-end;

        list-style: none;
        padding: 0;
        margin: 0;

        li {
          a {
            color: $txtColor;
            font-size: 0.8em;
            padding: 10px 14px 8px;
            display: inline-block;
            font-weight: 600;
            letter-spacing: 0.2px;
            transition: all ease-in-out 200ms;

            &:hover {
              background: darken($background, 4%);
              text-decoration: none;
            }

            &.phone {
              background: $color_green;
              color: $base;
              margin-left: 7px;

              @media (max-width: $screen-xs-max) {
                display: none;
              }

              &:hover {
                background: darken($color_green, 4%);
              }
            }
          }

          .branches {
            float: left;

            &.open {
              a {
                background: darken($background, 4%);
              }
            }
            a {
              text-decoration: none;
            }
            .dropdown-menu {
              color: $txtColor;
              border-color: $background;
              margin-top: 8px;
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

              a {
                color: lighten($txtColor, 10%);
                transition: all ease-in-out 200ms;

                &:hover {
                  background: #eff6f5;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: $screen-lg-max) {
      .container {
        max-width: 100%;
      }
    }
  }

  .main-header {
    position: absolute;
    width: 100%;
    background: $base;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    z-index: 999;

    @media (max-width: $screen-lg-max) {
      .container {
        max-width: 100%;
      }
    }

    .logo {
      display: inline-block;
      padding: 10px 0;
      float: left;

      img {
        height: 70px;
        width: auto;

        @media (max-width: $screen-md-max) {
          height: 40px;
        }
      }
    }

    nav.navigation {
      float: right;
      padding: 8px 0;

      // hidden mobile
      @media (max-width: $screen-md-max) {
        display: none;
        top: 60px;
        background: #fff;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
        padding: 0;

        ul {
          padding: 16px 0 !important;

          li {
            text-align: left !important;
            float: none !important;

            a {
              padding: 16px 20px !important;
            }
          }
        }

        &.active {
          display: block;
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
        }
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          float: left;
          list-style-type: none;
          padding: 25px 0;
          text-align: center;
          position: relative;
          &.active {
            a {
              color: $primary;
            }
          }
          @media (max-width: $screen-lg-max) {
            ul {
              display: none;
            }
          }

          @media (max-width: $screen-md-max) {
            padding: 0;
            width: 100%;
          }

          a {
            color: $txtColor;
            padding: 25px 20px;
            font-size: 17px;
            font-weight: 600;
            transition: all ease-in-out 200ms;

            @media (max-width: $screen-lg-max) {
              font-size: 15px;
              letter-spacing: -0.15px;
            }

            @media (max-width: $screen-md-max) {
              display: block;
              font-size: 18px;
              letter-spacing: 0;
              width: 100%;
            }

            &:hover {
              color: $primary;
              text-decoration: none;
              transition: all 0.3s;
            }
          }

          @media (min-width: $screen-xl-min) {
            &.menu-item-has-children {
              > a {
                padding-bottom: 60px;

                &:after {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  margin-left: 0.3em;
                  vertical-align: middle;
                  content: '';
                  border-top: 0.3em solid;
                  border-right: 0.3em solid transparent;
                  border-left: 0.3em solid transparent;
                  color: lighten($txtColor, 10%);
                }
              }
            }
          }

          &:hover > .sub-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(58px);
          }

          ul {
            position: absolute;
            left: 0;
            visibility: hidden;
            opacity: 0;
            z-index: 10;
            text-align: left;
            background: #fff;
            transform: translateY(38px);
            padding: 13.6px 0;
            padding: 0.85rem 0;
            transition: all 0.15s ease-out;
            box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
            border-radius: 4px;

            li {
              display: block;
              width: 100%;
              text-align: left;
              padding: 0;

              a {
                display: block;
                padding: 12px 24px;

                &:hover {
                  background: lighten($background, 1%);
                }
              }
            }

            // arrow top
            &:before {
              content: ' ';
              position: absolute;
              top: -8px;
              top: -0.5rem;
              left: calc(50% - 0.6rem);
              border-top: 0;
              border-left: 9.6px solid transparent;
              border-left: 0.6rem solid transparent;
              border-right: 9.6px solid transparent;
              border-right: 0.6rem solid transparent;
              border-bottom: 9.6px solid #fff;
              border-bottom: 0.6rem solid #fff;
            }
          }
        }
      }
    }

    .navigation--icon {
      display: block;
      margin: 5px;
      height: 50px;
      float: right;
      padding: 13px 0;
      font-weight: bold;
      line-height: 2.25;
      text-transform: uppercase;
      font-size: 80%;
      user-select: none;

      &:after {
        content: '';
        display: block;
        background-image: url(../images/icon-navigation.svg);
        background-size: 24px 24px;
        height: 24px;
        width: 24px;
        float: right;
        margin-left: 14px;
      }

      @media (min-width: $screen-lg-min) {
        display: none;
      }
    }
  }
}
