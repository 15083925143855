.hero {
  position: relative;
  padding: 110px 0 140px;

  &--overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(82, 86, 94, 0.4);
  }

  &--large {
    padding: 0 !important;

    .hero {
      @media (max-width: $screen-md-max) {
        margin-top: 60px;
        padding: 0 !important;
      }

      &__top {
        background: #eff6f5;
        padding: 80px 0 50px;

        @media (max-width: $screen-md-max) {
          padding: 2rem;
        }

        .title {
          span {
            color: #438380;
          }
        }
      }

      &__bottom {
        height: 340px;
        background-image: url(/app/uploads/2019/12/hero.jpg);
        background-size: cover;

        @media (max-width: $screen-md-max) {
          height: 180px;
        }
      }
    }
  }

  &__title {
    display: flex;
    margin-bottom: 1rem;

    h2 {
      font-size: $font-size-h-1;
      color: $base;
      margin: 0;

      @media (max-width: $screen-md-max) {
        font-size: $font-size-h-3;
      }
    }
  }

  &__content {
    display: flex;
    margin-bottom: 1rem;
    color: $base;

    p {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;
  }
}

.navigation-tiles {
  padding: 0;
  background-image: linear-gradient(90deg, #fff 50%, #eff6f5 50%);
  margin-bottom: 2rem;

  @media (max-width: $screen-md-max) {
    background: #eff6f5;
  }

  &__background {
    background: #eff6f5;
    padding: 48px 0;
    border-radius: 1.5rem;

    @media (max-width: $screen-md-max) {
      padding: 2rem 0;
    }
  }

  &__title {
    h2 {
      font-size: $font-size-h-1;
      color: #52565e;

      @media (max-width: $screen-md-max) {
        font-size: 1.8rem;
      }

      span {
        color: $color_green;
      }
    }

    @media (max-width: $screen-md-max) {
      margin-bottom: 2rem;
    }
  }

  &__tiles {
    display: flex;

    .tile {
      display: flex;
      padding: 2rem 1.5rem 1.5rem;
      background: $base;
      border: 2px solid transparent;
      border-radius: 6px;
      gap: 1rem;

      &--focus {
        border-color: #438380;
      }

      &__icon {
        display: flex;
        svg {
          color: $color_green;
        }
      }

      &__title {
        h3 {
          font-size: 19px;
          font-weight: bold;
          color: $color_green;
          transition: color ease-in-out 200ms;

          &:hover {
            text-decoration: none;
            color: #29504f;
          }
        }

        a {
          transition: color ease-in-out 200ms;

          &:hover {
            text-decoration: none;
            color: #29504f;
          }
        }
      }

      &__text {
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.locations {
  @media (max-width: $screen-md-max) {
    background: #eff6f5;
    padding: 1rem 0 !important;
  }
}

section {
  .section-background {
    background-color: #eff6f5;
    padding: 4rem 0;
    border-radius: 1.5rem;

    @media (max-width: $screen-md-max) {
      padding: 2rem 0;
    }

    &--dark {
      background-color: #438380;
      color: $base;
    }
  }

  .title {
    font-size: 35px;

    @media (max-width: $screen-md-max) {
      font-size: 1.8rem;
    }

    &--primary {
      color: $color_green;
    }

    &--white {
      color: $base;
    }
  }

  .card {
    border: none;
    border-radius: 0.4rem;

    &--gray {
      background-color: #f9f9f9;
    }

    &--white {
      @media (max-width: $screen-md-max) {
        .card {
          &__body {
            padding: 1rem 0;
          }
        }
      }
    }

    &--employee {
      .card__image {
        height: 320px;
        padding-bottom: 1.5rem;

        @media (max-width: $screen-md-max) {
          height: 280px;
        }

        .content {
          z-index: 1;

          h3 {
            margin-bottom: 0.25rem;
          }

          a:has(h3) {
          }

          span {
            color: $base;
            font-size: 15px;
          }

          .read-more {
            color: $base;
            font-size: 1rem;
            margin-top: 1rem;
            transition: all ease-in-out 200ms;
          }
        }
      }
    }

    &__image {
      background-image: url(/app/uploads/2019/12/hero.jpg);
      background-size: cover;
      position: relative;
      display: flex;
      align-items: end;
      background-color: #eee;
      height: 140px;
      padding: 1rem 1.5rem;
      border-radius: 0.4rem;
      overflow: hidden;

      @media (max-width: $screen-md-max) {
        padding: 1rem;
      }

      a {
        z-index: 1;

        &:hover {
          text-decoration: none;
        }
      }

      h3 {
        font-size: 19px;
        font-weight: bold;
        color: $base;
        z-index: 1;
        margin-bottom: 0;
        transition: all ease-in-out 200ms;

        &:hover {
          text-decoration: none;
        }
      }

      &:has(h3)::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          100% 100% at 0% 100%,
          rgba(0, 0, 0, 0.5) 33.33%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    &__body {
      padding: 1.5rem;

      @media (max-width: $screen-md-max) {
        padding: 1rem;
      }

      a:has(h3) {
        color: $color_green;
        transition: color ease-in-out 200ms;

        &:hover {
          text-decoration: none;
          color: #29504f;
        }
      }

      h3 {
        font-size: 19px;
        font-weight: bold;
        transition: color ease-in-out 200ms;

        &:hover {
          text-decoration: none;
          color: #29504f;
        }
      }

      p {
        font-size: 15px;
      }

      address {
        font-size: 15px;
      }
    }
  }

  &.content,
  .content {
    h2,
    h3,
    h4 {
      font-size: 19px;
      font-weight: bold;
      margin-top: 2rem;
      margin-bottom: 0.5em;

      @media (max-width: $screen-md-max) {
        font-size: 1.2rem;
      }
    }

    &__focus {
      background-color: #f1f8f7;
      padding: 36px 34px;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      border-radius: 8px;

      h3 {
        margin-top: 0;
      }

      ul {
        padding-left: 1rem;
      }

      @media (min-width: $screen-md-min) {
        .two-columns {
          column-count: 2;
        }
      }
    }

    .sidebar {
      background-color: #438380;
      padding: 36px 34px;
      color: $base;
      border-radius: 8px;

      &__title {
        h3 {
          margin-top: 0;
        }
      }
      &__content {
        .read-more {
          color: $base;
          transition: all ease-in-out 200ms;
        }
      }
    }
  }
}

// @media (min-width: $screen-md-min) {}
