section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 48px 0;
  background-color: $base;

  @media (max-width: $screen-md-max) {
    padding: 32px 0 24px !important;
  }
}

.wrap {
  margin-top: 90px;

  @media (max-width: $screen-md-max) {
    margin-top: 60px;
  }
}

// .small-hero {
//   padding: 60px 0;

//   .title {
//     display: inline-block;
//     background: #fff;
//     padding: 24px 28px;
//     box-shadow: 0 0 80px rgba(0, 0, 0, 0.05);
//     h1 {
//       padding: 0;
//       margin: 0;
//     }
//   }
// }

// .content {
//   h1 {
//     font-size: $font-size-h-1;
//     @media (max-width: $screen-md-max) {
//       font-size: $font-size-h-2;
//     }
//   }
//   // h2 {
//   // 	margin-top: 24px;
//   // 	margin-bottom: 8px;
//   // }
// }

// .breadcrumbs {
//   font-weight: bold;
//   font-size: 16px;
//   padding-top: 32px;

//   @media (max-width: $screen-md-max) {
//     padding-top: 16px;
//     font-size: 14px;
//   }

//   &,
//   a {
//     color: $primary;
//   }

//   .breadcrumb_last {
//     color: $txtColor;
//     font-weight: normal;
//   }
// }

.inschrijven {
  .call-to-action,
  .footer__before,
  .footer__main {
    display: none !important;
  }
}

.file-pdf {
  background: darken($background, 4);
  padding: 25px 20px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 85%;

  span {
    color: $txtColor;

    &:before {
      content: '';
      display: block;
      background-image: url(../images/pdf--icon.svg);
      background-size: 50px 50px;
      height: 50px;
      width: 50px;
      margin: 0 auto;
      margin-bottom: 8px;
    }
  }
}

.archive {
  section.doelgroepen {
    padding-top: 0;
    background: $background;

    a {
      text-decoration: none;
      outline: none;
      .doelgroep {
        border-radius: 8px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .image {
          position: relative;
          img {
            width: 100%;
            height: auto;
          }

          .title {
            transition: 0.3s;

            width: 100%;
            text-align: left;
            padding: 16px 24px 8px;
            color: $base;

            @media (max-width: $screen-xs-max) {
              position: absolute;
              bottom: 0;

              padding: 16px 24px;

              span {
                display: none;
              }
            }

            h3 {
              line-height: 1;
              margin: 0;
            }
            span {
              color: lighten($base, 20);
              line-height: 1;
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}

.archive {
  section.vestigingen {
    padding-top: 0;
    background: $background;

    a {
      text-decoration: none;
      outline: none;
      .vestiging {
        border-radius: 8px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .image {
          position: relative;
          img {
            width: 100%;
            height: auto;
          }

          .title {
            transition: 0.3s;

            width: 100%;
            text-align: left;
            padding: 16px 24px 8px;
            color: $base;

            @media (max-width: $screen-xs-max) {
              position: absolute;
              bottom: 0;

              padding: 16px 24px;

              span {
                display: none;
              }
            }

            h3 {
              line-height: 1;
              margin: 0;
            }
            span {
              color: lighten($base, 20);
              line-height: 1;
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}

.archive {
  section.teamleden {
    padding-top: 0;
    background: $background;

    a {
      text-decoration: none;
      outline: none;
      .teamlid {
        border-radius: 8px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .image {
          position: relative;
          img {
            width: 100%;
            height: auto;
          }

          .title {
            transition: 0.3s;
            width: 100%;
            text-align: left;
            padding: 24px 16px;
            color: $base;

            @media (max-width: $screen-xs-max) {
              position: absolute;
              bottom: 0;
              padding: 16px 24px;

              span {
                display: none;
              }
            }

            h3 {
              line-height: 1;
              margin: 0;
              padding-bottom: 24px;
            }
            span {
              color: lighten($base, 20);
              line-height: 1;
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}

.archive {
  section.klachten {
    padding-top: 0;
    background: $background;

    a {
      text-decoration: none;
      outline: none;
      color: $primary;
      &:hover {
        color: $green;
      }
      .klacht {
        border-radius: 8px;
        margin-bottom: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .image {
          position: relative;
          img {
            width: 100%;
            height: auto;
          }

          .title {
            transition: 0.3s;

            width: 100%;
            text-align: left;
            padding: 16px 24px;
            color: $base;

            @media (max-width: $screen-xs-max) {
              position: relative;
              bottom: 0;

              padding: 16px 24px;

              span {
                display: none;
              }
            }

            h3 {
              line-height: 1;
              margin: 0;
              font-size: $font-size-t-m;
            }
            span {
              color: lighten($base, 20);
              line-height: 1;
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}
.page {
  .plaatsen {
    .plaats {
      .title {
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        width: 100%;
        text-align: left;
        padding: 16px 24px;
        color: $base;
        border-radius: 8px;
        margin-bottom: 15px;
        a {
          line-height: 1;
          margin: 0;
          font-size: 18px;
          color: $base;
        }
      }
    }
  }
}
