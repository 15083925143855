body,
html {
  font-family: $font;
  font-size: $font-size-t-m;
  color: #54585b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  span.h1 {
    font-size: $font-size-h-1;
  }
  h2,
  span.h2 {
    font-size: $font-size-h-2;
  }
  h3,
  span.h3 {
    font-size: $font-size-h-3;
  }

  p.big {
    font-size: $font-size-t-l;
  }
  p,
  p.med {
    font-size: $font-size-t-m;
  }
  p.small {
    font-size: $font-size-t-s;
  }

  a {
    color: #438380;
  }
}

.row-gap {
  row-gap: 1.5rem;
}

.vertical-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

@media (min-width: $screen-md-min) {
  //Media Query
}

.read-more {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: $color_green;
  text-decoration: none;
  transition: all ease-in-out 200ms;

  &:hover {
    text-decoration: none;
    color: #29504f;
  }

  svg {
    font-size: 0.95rem;
    margin-left: 0.5rem;
  }
}

ul.reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.button-bar {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }
}
