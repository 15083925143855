.button {
  display: inline-block;
  background-color: #438380;
  color: #fff;
  min-width: 140px;
  padding: 12px 20px;
  border: 2px solid #438380;
  border-radius: 8px;
  font-size: 17px;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 200ms;
  font-weight: 500;
  letter-spacing: 0.2px;

  &:hover {
    background: darken(#438380, 4%);
    border-color: darken(#438380, 4%);
    color: $base;
    text-decoration: none;
  }

  &--secondary {
    background-color: $base;
    color: #52565e;

    &:hover {
      background-color: darken($base, 4%);
      color: #52565e;
    }
  }

  &--inverted {
    background-color: transparent;
    border-color: $base;

    &:hover {
      background-color: #3c7673;
      border-color: $base;
    }
  }

  &--transparent {
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.btn-box {
  margin-top: 16px;
  float: left;
  width: 100%;

  &.btn-center {
    text-align: center;
  }
}
.connected_klachten {
  .button {
    font-weight: normal;
    padding: 12px 15px 9px;
    min-width: auto;
    background: lighten($primary, 4%) !important;
    &:hover {
      background: $primary !important;
    }
  }
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: none;
}
