/** Import Bootstrap functions */
@import '~bootstrap/scss/functions';

$theme-colors: (
  primary: #438380,
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

// Grid settings
$enable-flex: true;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$primary: #438380;
$base: #fff;
$base-i: #faf7f4;

// Optimum Osteopathie colors
$background: #f1f8f7;
$txtColor: #52565e;
$green: #254846;
$blue: #2b5a6d;
$green-light: #438380;
$blue-light: #438cab;
$orange: #f89406;

$color_green: #438380;

// Text styles

$font: 'Inter', sans-serif;
$font-size-h-1: 35px;
$font-size-h-2: 28px;
$font-size-h-3: 26px;
$font-size-t-l: 20px;
$font-size-t-m: 15px;
$font-size-t-s: 12px;

// Defining breakpoint values
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
