/** Search form */
.search-form {
  @extend .form-inline;
}

.search-form label {
  @extend .form-group;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// CF7
.wpcf7 {
    label {
      font-weight: bold;
    }
    .wpcf7-form-control-wrap {
      font-weight: normal;
        display: inline-block;
        width: 100%;
        padding: 4px 0;
        input, textarea {
            width: 100%;
            border: 1px solid darken($background, 14);
            padding: 14px 16px 8px;
            border-radius: 0;
            outline: none;
            resize: none;
            &:focus {
                border-color: darken($background, 32);
            }
        }
        input[type="radio"] {
          width: auto;
          margin-right: 5px;
        }

        select {
            outline: none;
            position: relative;
            z-index: 2;
            width: 100%;
            height: 40px;
            margin: 0;
            padding-top: 10px;
            padding-right: calc(10% + 50px);
            padding-bottom: 10px;
            padding-left: 12px;
            border: 0;
            border-radius: 0;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            background: none;
            -webkit-appearance: none;
        }
    }

    .kind_geslacht {
        background: $base;
        border: 1px solid darken($background, 14);
    }

    .button {
        border: none;
        outline: none;
    }

    p {
        margin-bottom: 0.5rem!important;
    }

    label, span.wpcf7-list-item {
        width: 100%;
        margin: 0;

        input[type='checkbox'] {
            width: initial;
        }
    }

    .aanhef {
        span.wpcf7-list-item {
            width: initial;
            margin-right: 8px;

            input {
                margin: 3px 8px 0 0;
                float: left;
                width: initial;
            }
        }
    }
}

// Gravity Forms
.gform_wrapper {
    label {
      font-weight: bold;
    }
    .ginput_container {
      font-weight: normal;
        display: inline-block;
        width: 100%;
        padding: 4px 0;
        input, textarea {
            width: 100%;
            border: 1px solid darken($background, 14);
            padding: 14px 16px 8px;
            border-radius: 0;
            outline: none;
            resize: none;
            &:focus {
                border-color: darken($background, 32);
            }
        }
        input[type="radio"] {
          width: auto;
          margin-right: 5px;
        }
        input[type="checkbox"] {
          width: auto;
          padding: 0;
        }

        select {
            outline: none;
            position: relative;
            z-index: 2;
            width: 100%;
            height: 40px;
            margin: 0;
            padding: 14px 16px 8px;
            border-radius: 0;
            border: 1px solid darken($background, 14);
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            -webkit-appearance: none;
        }
    }


    .button {
        border: none;
        outline: none;
    }

    p {
        margin-bottom: 0.5rem!important;
    }

}

table {
    font-size: 90%;
    thead, tbody {
        background: lighten($background, 2);
    }
    th, td {
        border-color: darken($background, 14)!important;
    }

    thead tr th {
        font-weight: normal;
        font-style: italic;

        &:first-child {
            font-weight: bold;
            font-size: normal;
        }
    }

    tr td:first-child {
        font-weight: bold;
    }

    @media (min-width: $screen-md-min) {
        &.table-responsive {
            display: inline-table;
        }
    }
}

ol {
    font-size: 80%;
    padding-left: 20px;

    font-style: italic;

    margin-bottom: 3rem;
}
