/** Import Bootstrap functions */

/** Bootstrap navbar fix (https://git.io/fADqW) */

/** Import everything from autoload */

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/* line 1, node_modules/bootstrap/scss/_root.scss */

:root {
  --blue: #2b5a6d;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f89406;
  --yellow: #ffc107;
  --green: #254846;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #438380;
  --secondary: #6c757d;
  --success: #254846;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/* line 19, node_modules/bootstrap/scss/_reboot.scss */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* line 25, node_modules/bootstrap/scss/_reboot.scss */

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* line 35, node_modules/bootstrap/scss/_reboot.scss */

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/* line 46, node_modules/bootstrap/scss/_reboot.scss */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

/* line 62, node_modules/bootstrap/scss/_reboot.scss */

[tabindex="-1"]:focus {
  outline: 0 !important;
}

/* line 72, node_modules/bootstrap/scss/_reboot.scss */

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

/* line 88, node_modules/bootstrap/scss/_reboot.scss */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* line 97, node_modules/bootstrap/scss/_reboot.scss */

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 110, node_modules/bootstrap/scss/_reboot.scss */

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

/* line 119, node_modules/bootstrap/scss/_reboot.scss */

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/* line 125, node_modules/bootstrap/scss/_reboot.scss */

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 132, node_modules/bootstrap/scss/_reboot.scss */

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

/* line 139, node_modules/bootstrap/scss/_reboot.scss */

dt {
  font-weight: 700;
}

/* line 143, node_modules/bootstrap/scss/_reboot.scss */

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

/* line 148, node_modules/bootstrap/scss/_reboot.scss */

blockquote {
  margin: 0 0 1rem;
}

/* line 152, node_modules/bootstrap/scss/_reboot.scss */

b,
strong {
  font-weight: bolder;
}

/* line 157, node_modules/bootstrap/scss/_reboot.scss */

small {
  font-size: 80%;
}

/* line 166, node_modules/bootstrap/scss/_reboot.scss */

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

/* line 174, node_modules/bootstrap/scss/_reboot.scss */

sub {
  bottom: -.25em;
}

/* line 175, node_modules/bootstrap/scss/_reboot.scss */

sup {
  top: -.5em;
}

/* line 182, node_modules/bootstrap/scss/_reboot.scss */

a {
  color: #438380;
  text-decoration: none;
  background-color: transparent;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

a:hover {
  color: #29504f;
  text-decoration: underline;
}

/* line 199, node_modules/bootstrap/scss/_reboot.scss */

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

/* line 208, node_modules/bootstrap/scss/_reboot.scss */

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

/* line 218, node_modules/bootstrap/scss/_reboot.scss */

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

/* line 226, node_modules/bootstrap/scss/_reboot.scss */

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

/* line 240, node_modules/bootstrap/scss/_reboot.scss */

figure {
  margin: 0 0 1rem;
}

/* line 250, node_modules/bootstrap/scss/_reboot.scss */

img {
  vertical-align: middle;
  border-style: none;
}

/* line 255, node_modules/bootstrap/scss/_reboot.scss */

svg {
  overflow: hidden;
  vertical-align: middle;
}

/* line 267, node_modules/bootstrap/scss/_reboot.scss */

table {
  border-collapse: collapse;
}

/* line 271, node_modules/bootstrap/scss/_reboot.scss */

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

/* line 279, node_modules/bootstrap/scss/_reboot.scss */

th {
  text-align: inherit;
}

/* line 290, node_modules/bootstrap/scss/_reboot.scss */

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* line 299, node_modules/bootstrap/scss/_reboot.scss */

button {
  border-radius: 0;
}

/* line 308, node_modules/bootstrap/scss/_reboot.scss */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/* line 313, node_modules/bootstrap/scss/_reboot.scss */

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* line 324, node_modules/bootstrap/scss/_reboot.scss */

button,
input {
  overflow: visible;
}

/* line 329, node_modules/bootstrap/scss/_reboot.scss */

button,
select {
  text-transform: none;
}

/* line 337, node_modules/bootstrap/scss/_reboot.scss */

select {
  word-wrap: normal;
}

/* line 345, node_modules/bootstrap/scss/_reboot.scss */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* line 358, node_modules/bootstrap/scss/_reboot.scss */

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

/* line 365, node_modules/bootstrap/scss/_reboot.scss */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* line 373, node_modules/bootstrap/scss/_reboot.scss */

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

/* line 380, node_modules/bootstrap/scss/_reboot.scss */

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

/* line 392, node_modules/bootstrap/scss/_reboot.scss */

textarea {
  overflow: auto;
  resize: vertical;
}

/* line 398, node_modules/bootstrap/scss/_reboot.scss */

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/* line 413, node_modules/bootstrap/scss/_reboot.scss */

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

/* line 425, node_modules/bootstrap/scss/_reboot.scss */

progress {
  vertical-align: baseline;
}

/* line 430, node_modules/bootstrap/scss/_reboot.scss */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* line 435, node_modules/bootstrap/scss/_reboot.scss */

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

/* line 448, node_modules/bootstrap/scss/_reboot.scss */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 457, node_modules/bootstrap/scss/_reboot.scss */

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

/* line 466, node_modules/bootstrap/scss/_reboot.scss */

output {
  display: inline-block;
}

/* line 470, node_modules/bootstrap/scss/_reboot.scss */

summary {
  display: list-item;
  cursor: pointer;
}

/* line 475, node_modules/bootstrap/scss/_reboot.scss */

template {
  display: none;
}

/* line 481, node_modules/bootstrap/scss/_reboot.scss */

[hidden] {
  display: none !important;
}

/* line 7, node_modules/bootstrap/scss/_type.scss */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

/* line 16, node_modules/bootstrap/scss/_type.scss */

h1,
.h1 {
  font-size: 2.5rem;
}

/* line 17, node_modules/bootstrap/scss/_type.scss */

h2,
.h2 {
  font-size: 2rem;
}

/* line 18, node_modules/bootstrap/scss/_type.scss */

h3,
.h3 {
  font-size: 1.75rem;
}

/* line 19, node_modules/bootstrap/scss/_type.scss */

h4,
.h4 {
  font-size: 1.5rem;
}

/* line 20, node_modules/bootstrap/scss/_type.scss */

h5,
.h5 {
  font-size: 1.25rem;
}

/* line 21, node_modules/bootstrap/scss/_type.scss */

h6,
.h6 {
  font-size: 1rem;
}

/* line 23, node_modules/bootstrap/scss/_type.scss */

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* line 29, node_modules/bootstrap/scss/_type.scss */

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 34, node_modules/bootstrap/scss/_type.scss */

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 39, node_modules/bootstrap/scss/_type.scss */

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 44, node_modules/bootstrap/scss/_type.scss */

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 55, node_modules/bootstrap/scss/_type.scss */

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* line 67, node_modules/bootstrap/scss/_type.scss */

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

/* line 73, node_modules/bootstrap/scss/_type.scss */

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* line 84, node_modules/bootstrap/scss/_type.scss */

.list-unstyled,
.comment-list {
  padding-left: 0;
  list-style: none;
}

/* line 89, node_modules/bootstrap/scss/_type.scss */

.list-inline {
  padding-left: 0;
  list-style: none;
}

/* line 92, node_modules/bootstrap/scss/_type.scss */

.list-inline-item {
  display: inline-block;
}

/* line 95, node_modules/bootstrap/scss/_type.scss */

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

/* line 106, node_modules/bootstrap/scss/_type.scss */

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

/* line 112, node_modules/bootstrap/scss/_type.scss */

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* line 117, node_modules/bootstrap/scss/_type.scss */

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

/* line 122, node_modules/bootstrap/scss/_type.scss */

.blockquote-footer::before {
  content: "\2014\00A0";
}

/* line 8, node_modules/bootstrap/scss/_images.scss */

.img-fluid,
.wp-caption img {
  max-width: 100%;
  height: auto;
}

/* line 14, node_modules/bootstrap/scss/_images.scss */

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

/* line 29, node_modules/bootstrap/scss/_images.scss */

.figure,
.wp-caption {
  display: inline-block;
}

/* line 34, node_modules/bootstrap/scss/_images.scss */

.figure-img,
.wp-caption img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

/* line 39, node_modules/bootstrap/scss/_images.scss */

.figure-caption,
.wp-caption-text {
  font-size: 90%;
  color: #6c757d;
}

/* line 2, node_modules/bootstrap/scss/_code.scss */

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

/* line 8, node_modules/bootstrap/scss/_code.scss */

a > code {
  color: inherit;
}

/* line 14, node_modules/bootstrap/scss/_code.scss */

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

/* line 22, node_modules/bootstrap/scss/_code.scss */

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

/* line 31, node_modules/bootstrap/scss/_code.scss */

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

/* line 37, node_modules/bootstrap/scss/_code.scss */

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

/* line 45, node_modules/bootstrap/scss/_code.scss */

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

/* line 6, node_modules/bootstrap/scss/_grid.scss */

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  /* line 6, node_modules/bootstrap/scss/_grid.scss */

  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  /* line 6, node_modules/bootstrap/scss/_grid.scss */

  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  /* line 6, node_modules/bootstrap/scss/_grid.scss */

  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  /* line 6, node_modules/bootstrap/scss/_grid.scss */

  .container {
    max-width: 1140px;
  }
}

/* line 18, node_modules/bootstrap/scss/_grid.scss */

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* line 28, node_modules/bootstrap/scss/_grid.scss */

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* line 34, node_modules/bootstrap/scss/_grid.scss */

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

/* line 38, node_modules/bootstrap/scss/_grid.scss */

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

/* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

/* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-first {
  order: -1;
}

/* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-last {
  order: 13;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-0 {
  order: 0;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-1 {
  order: 1;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-2 {
  order: 2;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-3 {
  order: 3;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-4 {
  order: 4;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-5 {
  order: 5;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-6 {
  order: 6;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-7 {
  order: 7;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-8 {
  order: 8;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-9 {
  order: 9;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-10 {
  order: 10;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-11 {
  order: 11;
}

/* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.order-12 {
  order: 12;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-1 {
  margin-left: 8.33333%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-2 {
  margin-left: 16.66667%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-3 {
  margin-left: 25%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-4 {
  margin-left: 33.33333%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-5 {
  margin-left: 41.66667%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-6 {
  margin-left: 50%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-7 {
  margin-left: 58.33333%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-8 {
  margin-left: 66.66667%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-9 {
  margin-left: 75%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-10 {
  margin-left: 83.33333%;
}

/* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-first {
    order: -1;
  }

  /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-last {
    order: 13;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-0 {
    order: 0;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-1 {
    order: 1;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-2 {
    order: 2;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-3 {
    order: 3;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-4 {
    order: 4;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-5 {
    order: 5;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-6 {
    order: 6;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-7 {
    order: 7;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-8 {
    order: 8;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-9 {
    order: 9;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-10 {
    order: 10;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-11 {
    order: 11;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-sm-12 {
    order: 12;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-0 {
    margin-left: 0;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-3 {
    margin-left: 25%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-6 {
    margin-left: 50%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-9 {
    margin-left: 75%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-first {
    order: -1;
  }

  /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-last {
    order: 13;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-0 {
    order: 0;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-1 {
    order: 1;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-2 {
    order: 2;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-3 {
    order: 3;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-4 {
    order: 4;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-5 {
    order: 5;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-6 {
    order: 6;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-7 {
    order: 7;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-8 {
    order: 8;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-9 {
    order: 9;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-10 {
    order: 10;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-11 {
    order: 11;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-md-12 {
    order: 12;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-0 {
    margin-left: 0;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-3 {
    margin-left: 25%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-6 {
    margin-left: 50%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-9 {
    margin-left: 75%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-first {
    order: -1;
  }

  /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-last {
    order: 13;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-0 {
    order: 0;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-1 {
    order: 1;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-2 {
    order: 2;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-3 {
    order: 3;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-4 {
    order: 4;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-5 {
    order: 5;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-6 {
    order: 6;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-7 {
    order: 7;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-8 {
    order: 8;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-9 {
    order: 9;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-10 {
    order: 10;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-11 {
    order: 11;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-lg-12 {
    order: 12;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-0 {
    margin-left: 0;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-3 {
    margin-left: 25%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-6 {
    margin-left: 50%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-9 {
    margin-left: 75%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  /* line 31, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  /* line 36, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 43, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 48, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-first {
    order: -1;
  }

  /* line 50, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-last {
    order: 13;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-0 {
    order: 0;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-1 {
    order: 1;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-2 {
    order: 2;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-3 {
    order: 3;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-4 {
    order: 4;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-5 {
    order: 5;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-6 {
    order: 6;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-7 {
    order: 7;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-8 {
    order: 8;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-9 {
    order: 9;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-10 {
    order: 10;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-11 {
    order: 11;
  }

  /* line 53, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .order-xl-12 {
    order: 12;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-0 {
    margin-left: 0;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-3 {
    margin-left: 25%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-6 {
    margin-left: 50%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-9 {
    margin-left: 75%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  /* line 59, node_modules/bootstrap/scss/mixins/_grid-framework.scss */

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* line 5, node_modules/bootstrap/scss/_tables.scss */

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

/* line 11, node_modules/bootstrap/scss/_tables.scss */

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* line 18, node_modules/bootstrap/scss/_tables.scss */

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

/* line 23, node_modules/bootstrap/scss/_tables.scss */

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

/* line 34, node_modules/bootstrap/scss/_tables.scss */

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

/* line 45, node_modules/bootstrap/scss/_tables.scss */

.table-bordered {
  border: 1px solid #dee2e6;
}

/* line 48, node_modules/bootstrap/scss/_tables.scss */

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

/* line 54, node_modules/bootstrap/scss/_tables.scss */

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

/* line 62, node_modules/bootstrap/scss/_tables.scss */

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

/* line 75, node_modules/bootstrap/scss/_tables.scss */

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cadcdb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9dbfbd;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-primary:hover {
  background-color: #bbd2d1;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bbd2d1;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c2cccb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8ea09f;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-success:hover {
  background-color: #b4c0bf;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4c0bf;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-info:hover {
  background-color: #abdde5;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-light:hover {
  background-color: #ececf6;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 114, node_modules/bootstrap/scss/_tables.scss */

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

/* line 122, node_modules/bootstrap/scss/_tables.scss */

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/* line 130, node_modules/bootstrap/scss/_tables.scss */

.table-dark {
  color: #fff;
  background-color: #343a40;
}

/* line 134, node_modules/bootstrap/scss/_tables.scss */

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

/* line 140, node_modules/bootstrap/scss/_tables.scss */

.table-dark.table-bordered {
  border: 0;
}

/* line 145, node_modules/bootstrap/scss/_tables.scss */

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

/* line 171, node_modules/bootstrap/scss/_tables.scss */

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* line 179, node_modules/bootstrap/scss/_tables.scss */

.table-responsive > .table-bordered {
  border: 0;
}

/* line 7, node_modules/bootstrap/scss/_forms.scss */

.form-control,
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea,
.search-form .search-field {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 7, node_modules/bootstrap/scss/_forms.scss */

  .form-control,
  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form input[type="url"],
  .comment-form textarea,
  .search-form .search-field {
    transition: none;
  }
}

/* line 28, node_modules/bootstrap/scss/_forms.scss */

.form-control::-ms-expand,
.comment-form input[type="text"]::-ms-expand,
.comment-form input[type="email"]::-ms-expand,
.comment-form input[type="url"]::-ms-expand,
.comment-form textarea::-ms-expand,
.search-form .search-field::-ms-expand {
  background-color: transparent;
  border: 0;
}

/* line 14, node_modules/bootstrap/scss/mixins/_forms.scss */

.form-control:focus,
.comment-form input:focus[type="text"],
.comment-form input:focus[type="email"],
.comment-form input:focus[type="url"],
.comment-form textarea:focus,
.search-form .search-field:focus {
  color: #495057;
  background-color: #fff;
  border-color: #85c1be;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 37, node_modules/bootstrap/scss/_forms.scss */

.form-control::placeholder,
.comment-form input[type="text"]::placeholder,
.comment-form input[type="email"]::placeholder,
.comment-form input[type="url"]::placeholder,
.comment-form textarea::placeholder,
.search-form .search-field::placeholder {
  color: #6c757d;
  opacity: 1;
}

/* line 48, node_modules/bootstrap/scss/_forms.scss */

.form-control:disabled,
.comment-form input:disabled[type="text"],
.comment-form input:disabled[type="email"],
.comment-form input:disabled[type="url"],
.comment-form textarea:disabled,
.search-form .search-field:disabled,
.form-control[readonly],
.comment-form input[readonly][type="text"],
.comment-form input[readonly][type="email"],
.comment-form input[readonly][type="url"],
.comment-form textarea[readonly],
.search-form .search-field[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/* line 57, node_modules/bootstrap/scss/_forms.scss */

select.form-control:focus::-ms-value,
.search-form select.search-field:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

/* line 69, node_modules/bootstrap/scss/_forms.scss */

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

/* line 82, node_modules/bootstrap/scss/_forms.scss */

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/* line 90, node_modules/bootstrap/scss/_forms.scss */

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

/* line 97, node_modules/bootstrap/scss/_forms.scss */

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* line 110, node_modules/bootstrap/scss/_forms.scss */

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

/* line 122, node_modules/bootstrap/scss/_forms.scss */

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

/* line 137, node_modules/bootstrap/scss/_forms.scss */

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 145, node_modules/bootstrap/scss/_forms.scss */

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 155, node_modules/bootstrap/scss/_forms.scss */

select.form-control[size],
.search-form select.search-field[size],
select.form-control[multiple],
.search-form select.search-field[multiple] {
  height: auto;
}

/* line 161, node_modules/bootstrap/scss/_forms.scss */

textarea.form-control,
.comment-form textarea,
.search-form textarea.search-field {
  height: auto;
}

/* line 170, node_modules/bootstrap/scss/_forms.scss */

.form-group,
.comment-form p,
.search-form label {
  margin-bottom: 1rem;
}

/* line 174, node_modules/bootstrap/scss/_forms.scss */

.form-text {
  display: block;
  margin-top: 0.25rem;
}

/* line 184, node_modules/bootstrap/scss/_forms.scss */

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

/* line 190, node_modules/bootstrap/scss/_forms.scss */

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* line 202, node_modules/bootstrap/scss/_forms.scss */

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

/* line 208, node_modules/bootstrap/scss/_forms.scss */

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

/* line 213, node_modules/bootstrap/scss/_forms.scss */

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

/* line 218, node_modules/bootstrap/scss/_forms.scss */

.form-check-label {
  margin-bottom: 0;
}

/* line 222, node_modules/bootstrap/scss/_forms.scss */

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

/* line 229, node_modules/bootstrap/scss/_forms.scss */

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

/* line 30, node_modules/bootstrap/scss/mixins/_forms.scss */

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #254846;
}

/* line 38, node_modules/bootstrap/scss/mixins/_forms.scss */

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(37, 72, 70, 0.9);
  border-radius: 0.25rem;
}

/* line 54, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:valid,
.was-validated .comment-form input:valid[type="text"],
.comment-form .was-validated input:valid[type="text"],
.was-validated .comment-form input:valid[type="email"],
.comment-form .was-validated input:valid[type="email"],
.was-validated .comment-form input:valid[type="url"],
.comment-form .was-validated input:valid[type="url"],
.was-validated .comment-form textarea:valid,
.comment-form .was-validated textarea:valid,
.was-validated .search-form .search-field:valid,
.search-form .was-validated .search-field:valid,
.form-control.is-valid,
.comment-form input.is-valid[type="text"],
.comment-form input.is-valid[type="email"],
.comment-form input.is-valid[type="url"],
.comment-form textarea.is-valid,
.search-form .is-valid.search-field {
  border-color: #254846;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23254846' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 66, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:valid:focus,
.was-validated .comment-form input:valid:focus[type="text"],
.comment-form .was-validated input:valid:focus[type="text"],
.was-validated .comment-form input:valid:focus[type="email"],
.comment-form .was-validated input:valid:focus[type="email"],
.was-validated .comment-form input:valid:focus[type="url"],
.comment-form .was-validated input:valid:focus[type="url"],
.was-validated .comment-form textarea:valid:focus,
.comment-form .was-validated textarea:valid:focus,
.was-validated .search-form .search-field:valid:focus,
.search-form .was-validated .search-field:valid:focus,
.form-control.is-valid:focus,
.comment-form input.is-valid:focus[type="text"],
.comment-form input.is-valid:focus[type="email"],
.comment-form input.is-valid:focus[type="url"],
.comment-form textarea.is-valid:focus,
.search-form .is-valid.search-field:focus {
  border-color: #254846;
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.25);
}

/* line 71, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .comment-form input:valid[type="text"] ~ .valid-feedback,
.comment-form .was-validated input:valid[type="text"] ~ .valid-feedback,
.was-validated .comment-form input:valid[type="email"] ~ .valid-feedback,
.comment-form .was-validated input:valid[type="email"] ~ .valid-feedback,
.was-validated .comment-form input:valid[type="url"] ~ .valid-feedback,
.comment-form .was-validated input:valid[type="url"] ~ .valid-feedback,
.was-validated .comment-form textarea:valid ~ .valid-feedback,
.comment-form .was-validated textarea:valid ~ .valid-feedback,
.was-validated .search-form .search-field:valid ~ .valid-feedback,
.search-form .was-validated .search-field:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .comment-form input:valid[type="text"] ~ .valid-tooltip,
.comment-form .was-validated input:valid[type="text"] ~ .valid-tooltip,
.was-validated .comment-form input:valid[type="email"] ~ .valid-tooltip,
.comment-form .was-validated input:valid[type="email"] ~ .valid-tooltip,
.was-validated .comment-form input:valid[type="url"] ~ .valid-tooltip,
.comment-form .was-validated input:valid[type="url"] ~ .valid-tooltip,
.was-validated .comment-form textarea:valid ~ .valid-tooltip,
.comment-form .was-validated textarea:valid ~ .valid-tooltip,
.was-validated .search-form .search-field:valid ~ .valid-tooltip,
.search-form .was-validated .search-field:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.comment-form input.is-valid[type="text"] ~ .valid-feedback,
.comment-form input.is-valid[type="email"] ~ .valid-feedback,
.comment-form input.is-valid[type="url"] ~ .valid-feedback,
.comment-form textarea.is-valid ~ .valid-feedback,
.search-form .is-valid.search-field ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.comment-form input.is-valid[type="text"] ~ .valid-tooltip,
.comment-form input.is-valid[type="email"] ~ .valid-tooltip,
.comment-form input.is-valid[type="url"] ~ .valid-tooltip,
.comment-form textarea.is-valid ~ .valid-tooltip,
.search-form .is-valid.search-field ~ .valid-tooltip {
  display: block;
}

/* line 80, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated textarea.form-control:valid,
.was-validated .comment-form textarea:valid,
.comment-form .was-validated textarea:valid,
.was-validated .search-form textarea.search-field:valid,
.search-form .was-validated textarea.search-field:valid,
textarea.form-control.is-valid,
.comment-form textarea.is-valid,
.search-form textarea.is-valid.search-field {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

/* line 90, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #254846;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23254846' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 99, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #254846;
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.25);
}

/* line 104, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 115, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 125, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #254846;
}

/* line 129, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 139, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #254846;
}

/* line 142, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #254846;
}

/* line 147, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 153, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #366a67;
  background-color: #366a67;
}

/* line 160, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.25);
}

/* line 164, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #254846;
}

/* line 175, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #254846;
}

/* line 179, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 185, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #254846;
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.25);
}

/* line 30, node_modules/bootstrap/scss/mixins/_forms.scss */

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* line 38, node_modules/bootstrap/scss/mixins/_forms.scss */

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

/* line 54, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:invalid,
.was-validated .comment-form input:invalid[type="text"],
.comment-form .was-validated input:invalid[type="text"],
.was-validated .comment-form input:invalid[type="email"],
.comment-form .was-validated input:invalid[type="email"],
.was-validated .comment-form input:invalid[type="url"],
.comment-form .was-validated input:invalid[type="url"],
.was-validated .comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid,
.was-validated .search-form .search-field:invalid,
.search-form .was-validated .search-field:invalid,
.form-control.is-invalid,
.comment-form input.is-invalid[type="text"],
.comment-form input.is-invalid[type="email"],
.comment-form input.is-invalid[type="url"],
.comment-form textarea.is-invalid,
.search-form .is-invalid.search-field {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 66, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:invalid:focus,
.was-validated .comment-form input:invalid:focus[type="text"],
.comment-form .was-validated input:invalid:focus[type="text"],
.was-validated .comment-form input:invalid:focus[type="email"],
.comment-form .was-validated input:invalid:focus[type="email"],
.was-validated .comment-form input:invalid:focus[type="url"],
.comment-form .was-validated input:invalid:focus[type="url"],
.was-validated .comment-form textarea:invalid:focus,
.comment-form .was-validated textarea:invalid:focus,
.was-validated .search-form .search-field:invalid:focus,
.search-form .was-validated .search-field:invalid:focus,
.form-control.is-invalid:focus,
.comment-form input.is-invalid:focus[type="text"],
.comment-form input.is-invalid:focus[type="email"],
.comment-form input.is-invalid:focus[type="url"],
.comment-form textarea.is-invalid:focus,
.search-form .is-invalid.search-field:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 71, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .comment-form input:invalid[type="text"] ~ .invalid-feedback,
.comment-form .was-validated input:invalid[type="text"] ~ .invalid-feedback,
.was-validated .comment-form input:invalid[type="email"] ~ .invalid-feedback,
.comment-form .was-validated input:invalid[type="email"] ~ .invalid-feedback,
.was-validated .comment-form input:invalid[type="url"] ~ .invalid-feedback,
.comment-form .was-validated input:invalid[type="url"] ~ .invalid-feedback,
.was-validated .comment-form textarea:invalid ~ .invalid-feedback,
.comment-form .was-validated textarea:invalid ~ .invalid-feedback,
.was-validated .search-form .search-field:invalid ~ .invalid-feedback,
.search-form .was-validated .search-field:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .comment-form input:invalid[type="text"] ~ .invalid-tooltip,
.comment-form .was-validated input:invalid[type="text"] ~ .invalid-tooltip,
.was-validated .comment-form input:invalid[type="email"] ~ .invalid-tooltip,
.comment-form .was-validated input:invalid[type="email"] ~ .invalid-tooltip,
.was-validated .comment-form input:invalid[type="url"] ~ .invalid-tooltip,
.comment-form .was-validated input:invalid[type="url"] ~ .invalid-tooltip,
.was-validated .comment-form textarea:invalid ~ .invalid-tooltip,
.comment-form .was-validated textarea:invalid ~ .invalid-tooltip,
.was-validated .search-form .search-field:invalid ~ .invalid-tooltip,
.search-form .was-validated .search-field:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.comment-form input.is-invalid[type="text"] ~ .invalid-feedback,
.comment-form input.is-invalid[type="email"] ~ .invalid-feedback,
.comment-form input.is-invalid[type="url"] ~ .invalid-feedback,
.comment-form textarea.is-invalid ~ .invalid-feedback,
.search-form .is-invalid.search-field ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.comment-form input.is-invalid[type="text"] ~ .invalid-tooltip,
.comment-form input.is-invalid[type="email"] ~ .invalid-tooltip,
.comment-form input.is-invalid[type="url"] ~ .invalid-tooltip,
.comment-form textarea.is-invalid ~ .invalid-tooltip,
.search-form .is-invalid.search-field ~ .invalid-tooltip {
  display: block;
}

/* line 80, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated textarea.form-control:invalid,
.was-validated .comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid,
.was-validated .search-form textarea.search-field:invalid,
.search-form .was-validated textarea.search-field:invalid,
textarea.form-control.is-invalid,
.comment-form textarea.is-invalid,
.search-form textarea.is-invalid.search-field {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

/* line 90, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 99, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 104, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 115, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 125, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

/* line 129, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 139, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

/* line 142, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

/* line 147, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 153, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

/* line 160, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 164, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

/* line 175, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

/* line 179, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 185, node_modules/bootstrap/scss/mixins/_forms.scss */

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 258, node_modules/bootstrap/scss/_forms.scss */

.form-inline,
.search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 266, node_modules/bootstrap/scss/_forms.scss */

.form-inline .form-check,
.search-form .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  /* line 272, node_modules/bootstrap/scss/_forms.scss */

  .form-inline label,
  .search-form label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  /* line 280, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .form-group,
  .search-form .form-group,
  .form-inline .comment-form p,
  .comment-form .form-inline p,
  .search-form .comment-form p,
  .comment-form .search-form p,
  .form-inline .search-form label,
  .search-form .form-inline label,
  .search-form label {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  /* line 289, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .form-control,
  .search-form .form-control,
  .form-inline .comment-form input[type="text"],
  .comment-form .form-inline input[type="text"],
  .search-form .comment-form input[type="text"],
  .comment-form .search-form input[type="text"],
  .form-inline .comment-form input[type="email"],
  .comment-form .form-inline input[type="email"],
  .search-form .comment-form input[type="email"],
  .comment-form .search-form input[type="email"],
  .form-inline .comment-form input[type="url"],
  .comment-form .form-inline input[type="url"],
  .search-form .comment-form input[type="url"],
  .comment-form .search-form input[type="url"],
  .form-inline .comment-form textarea,
  .comment-form .form-inline textarea,
  .search-form .comment-form textarea,
  .comment-form .search-form textarea,
  .search-form .search-field {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  /* line 296, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .form-control-plaintext,
  .search-form .form-control-plaintext {
    display: inline-block;
  }

  /* line 300, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .input-group,
  .search-form .input-group,
  .form-inline .custom-select,
  .search-form .custom-select {
    width: auto;
  }

  /* line 307, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .form-check,
  .search-form .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  /* line 314, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .form-check-input,
  .search-form .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  /* line 322, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .custom-control,
  .search-form .custom-control {
    align-items: center;
    justify-content: center;
  }

  /* line 326, node_modules/bootstrap/scss/_forms.scss */

  .form-inline .custom-control-label,
  .search-form .custom-control-label {
    margin-bottom: 0;
  }
}

/* line 7, node_modules/bootstrap/scss/_buttons.scss */

.btn,
.comment-form input[type="submit"],
.search-form .search-submit {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 7, node_modules/bootstrap/scss/_buttons.scss */

  .btn,
  .comment-form input[type="submit"],
  .search-form .search-submit {
    transition: none;
  }
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn:hover,
.comment-form input:hover[type="submit"],
.search-form .search-submit:hover {
  color: #212529;
  text-decoration: none;
}

/* line 25, node_modules/bootstrap/scss/_buttons.scss */

.btn:focus,
.comment-form input:focus[type="submit"],
.search-form .search-submit:focus,
.btn.focus,
.comment-form input.focus[type="submit"],
.search-form .focus.search-submit {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 32, node_modules/bootstrap/scss/_buttons.scss */

.btn.disabled,
.comment-form input.disabled[type="submit"],
.search-form .disabled.search-submit,
.btn:disabled,
.comment-form input:disabled[type="submit"],
.search-form .search-submit:disabled {
  opacity: 0.65;
}

/* line 49, node_modules/bootstrap/scss/_buttons.scss */

a.btn.disabled,
.search-form a.disabled.search-submit,
fieldset:disabled a.btn,
fieldset:disabled .search-form a.search-submit,
.search-form fieldset:disabled a.search-submit {
  pointer-events: none;
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-primary {
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-primary:hover {
  color: #fff;
  background-color: #366a67;
  border-color: #32615f;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 150, 147, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #32615f;
  border-color: #2d5957;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 150, 147, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-secondary,
.comment-form input[type="submit"],
.search-form .search-submit {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-secondary:hover,
.comment-form input:hover[type="submit"],
.search-form .search-submit:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-secondary:focus,
.comment-form input:focus[type="submit"],
.search-form .search-submit:focus,
.btn-secondary.focus,
.comment-form input.focus[type="submit"],
.search-form .focus.search-submit {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-secondary.disabled,
.comment-form input.disabled[type="submit"],
.search-form .disabled.search-submit,
.btn-secondary:disabled,
.comment-form input:disabled[type="submit"],
.search-form .search-submit:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-secondary:not(:disabled):not(.disabled):active,
.comment-form input:not(:disabled):not(.disabled):active[type="submit"],
.search-form .search-submit:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.comment-form input:not(:disabled):not(.disabled).active[type="submit"],
.search-form .search-submit:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.comment-form .show > input.dropdown-toggle[type="submit"],
.search-form .show > .dropdown-toggle.search-submit {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.comment-form input:not(:disabled):not(.disabled):active:focus[type="submit"],
.search-form .search-submit:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.comment-form input:not(:disabled):not(.disabled).active:focus[type="submit"],
.search-form .search-submit:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.comment-form .show > input.dropdown-toggle:focus[type="submit"],
.search-form .show > .dropdown-toggle.search-submit:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-success {
  color: #fff;
  background-color: #254846;
  border-color: #254846;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-success:hover {
  color: #fff;
  background-color: #182f2d;
  border-color: #142625;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 99, 98, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #254846;
  border-color: #254846;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #142625;
  border-color: #0f1e1d;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 99, 98, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* line 60, node_modules/bootstrap/scss/_buttons.scss */

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

/* line 29, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 40, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

/* line 50, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-primary {
  color: #438380;
  border-color: #438380;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-primary:hover {
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #438380;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-success {
  color: #254846;
  border-color: #254846;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-success:hover {
  color: #fff;
  background-color: #254846;
  border-color: #254846;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #254846;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #254846;
  border-color: #254846;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 71, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 76, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

/* line 82, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 89, node_modules/bootstrap/scss/mixins/_buttons.scss */

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 77, node_modules/bootstrap/scss/_buttons.scss */

.btn-link {
  font-weight: 400;
  color: #438380;
  text-decoration: none;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-link:hover {
  color: #29504f;
  text-decoration: underline;
}

/* line 87, node_modules/bootstrap/scss/_buttons.scss */

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

/* line 93, node_modules/bootstrap/scss/_buttons.scss */

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

/* line 107, node_modules/bootstrap/scss/_buttons.scss */

.btn-lg,
.btn-group-lg > .btn,
.comment-form .btn-group-lg > input[type="submit"],
.search-form .btn-group-lg > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 111, node_modules/bootstrap/scss/_buttons.scss */

.btn-sm,
.btn-group-sm > .btn,
.comment-form .btn-group-sm > input[type="submit"],
.search-form .btn-group-sm > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 120, node_modules/bootstrap/scss/_buttons.scss */

.btn-block {
  display: block;
  width: 100%;
}

/* line 125, node_modules/bootstrap/scss/_buttons.scss */

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

/* line 134, node_modules/bootstrap/scss/_buttons.scss */

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/* line 1, node_modules/bootstrap/scss/_transitions.scss */

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  /* line 1, node_modules/bootstrap/scss/_transitions.scss */

  .fade {
    transition: none;
  }
}

/* line 4, node_modules/bootstrap/scss/_transitions.scss */

.fade:not(.show) {
  opacity: 0;
}

/* line 10, node_modules/bootstrap/scss/_transitions.scss */

.collapse:not(.show) {
  display: none;
}

/* line 15, node_modules/bootstrap/scss/_transitions.scss */

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 15, node_modules/bootstrap/scss/_transitions.scss */

  .collapsing {
    transition: none;
  }
}

/* line 2, node_modules/bootstrap/scss/_dropdown.scss */

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

/* line 9, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-toggle {
  white-space: nowrap;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 17, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* line 42, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-menu-left {
  right: auto;
  left: 0;
}

/* line 47, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

/* line 57, node_modules/bootstrap/scss/_dropdown.scss */

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 70, node_modules/bootstrap/scss/_dropdown.scss */

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 80, node_modules/bootstrap/scss/_dropdown.scss */

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

/* line 87, node_modules/bootstrap/scss/_dropdown.scss */

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

/* line 45, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropleft .dropdown-toggle::after {
  display: none;
}

/* line 49, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 97, node_modules/bootstrap/scss/_dropdown.scss */

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

/* line 106, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

/* line 116, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

/* line 123, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* line 153, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #438380;
}

/* line 160, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

/* line 172, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-menu.show {
  display: block;
}

/* line 177, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

/* line 187, node_modules/bootstrap/scss/_dropdown.scss */

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

/* line 4, node_modules/bootstrap/scss/_button-group.scss */

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

/* line 10, node_modules/bootstrap/scss/_button-group.scss */

.btn-group > .btn,
.comment-form .btn-group > input[type="submit"],
.search-form .btn-group > .search-submit,
.btn-group-vertical > .btn,
.comment-form .btn-group-vertical > input[type="submit"],
.search-form .btn-group-vertical > .search-submit {
  position: relative;
  flex: 1 1 auto;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.btn-group > .btn:hover,
.comment-form .btn-group > input:hover[type="submit"],
.search-form .btn-group > .search-submit:hover,
.btn-group-vertical > .btn:hover,
.comment-form .btn-group-vertical > input:hover[type="submit"],
.search-form .btn-group-vertical > .search-submit:hover {
  z-index: 1;
}

/* line 19, node_modules/bootstrap/scss/_button-group.scss */

.btn-group > .btn:focus,
.comment-form .btn-group > input:focus[type="submit"],
.search-form .btn-group > .search-submit:focus,
.btn-group > .btn:active,
.comment-form .btn-group > input:active[type="submit"],
.search-form .btn-group > .search-submit:active,
.btn-group > .btn.active,
.comment-form .btn-group > input.active[type="submit"],
.search-form .btn-group > .active.search-submit,
.btn-group-vertical > .btn:focus,
.comment-form .btn-group-vertical > input:focus[type="submit"],
.search-form .btn-group-vertical > .search-submit:focus,
.btn-group-vertical > .btn:active,
.comment-form .btn-group-vertical > input:active[type="submit"],
.search-form .btn-group-vertical > .search-submit:active,
.btn-group-vertical > .btn.active,
.comment-form .btn-group-vertical > input.active[type="submit"],
.search-form .btn-group-vertical > .active.search-submit {
  z-index: 1;
}

/* line 28, node_modules/bootstrap/scss/_button-group.scss */

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* line 33, node_modules/bootstrap/scss/_button-group.scss */

.btn-toolbar .input-group {
  width: auto;
}

/* line 40, node_modules/bootstrap/scss/_button-group.scss */

.btn-group > .btn:not(:first-child),
.comment-form .btn-group > input:not(:first-child)[type="submit"],
.search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

/* line 46, node_modules/bootstrap/scss/_button-group.scss */

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.comment-form .btn-group > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.comment-form .btn-group > .btn-group:not(:last-child) > input[type="submit"],
.search-form .btn-group > .btn-group:not(:last-child) > .search-submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 51, node_modules/bootstrap/scss/_button-group.scss */

.btn-group > .btn:not(:first-child),
.comment-form .btn-group > input:not(:first-child)[type="submit"],
.search-form .btn-group > .search-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.comment-form .btn-group > .btn-group:not(:first-child) > input[type="submit"],
.search-form .btn-group > .btn-group:not(:first-child) > .search-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 69, node_modules/bootstrap/scss/_button-group.scss */

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

/* line 73, node_modules/bootstrap/scss/_button-group.scss */

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

/* line 79, node_modules/bootstrap/scss/_button-group.scss */

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

/* line 84, node_modules/bootstrap/scss/_button-group.scss */

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split,
.search-form .btn-group-sm > .search-submit + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

/* line 89, node_modules/bootstrap/scss/_button-group.scss */

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split,
.search-form .btn-group-lg > .search-submit + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

/* line 111, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* line 116, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-vertical > .btn,
.comment-form .btn-group-vertical > input[type="submit"],
.search-form .btn-group-vertical > .search-submit,
.btn-group-vertical > .btn-group {
  width: 100%;
}

/* line 121, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-vertical > .btn:not(:first-child),
.comment-form .btn-group-vertical > input:not(:first-child)[type="submit"],
.search-form .btn-group-vertical > .search-submit:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

/* line 127, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.comment-form .btn-group-vertical > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.comment-form .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"],
.search-form .btn-group-vertical > .btn-group:not(:last-child) > .search-submit {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 132, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-vertical > .btn:not(:first-child),
.comment-form .btn-group-vertical > input:not(:first-child)[type="submit"],
.search-form .btn-group-vertical > .search-submit:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.comment-form .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"],
.search-form .btn-group-vertical > .btn-group:not(:first-child) > .search-submit {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 152, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-toggle > .btn,
.comment-form .btn-group-toggle > input[type="submit"],
.search-form .btn-group-toggle > .search-submit,
.btn-group-toggle > .btn-group > .btn,
.comment-form .btn-group-toggle > .btn-group > input[type="submit"],
.search-form .btn-group-toggle > .btn-group > .search-submit {
  margin-bottom: 0;
}

/* line 156, node_modules/bootstrap/scss/_button-group.scss */

.btn-group-toggle > .btn input[type="radio"],
.comment-form .btn-group-toggle > input[type="submit"] input[type="radio"],
.search-form .btn-group-toggle > .search-submit input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.comment-form .btn-group-toggle > input[type="submit"] input[type="checkbox"],
.search-form .btn-group-toggle > .search-submit input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"],
.search-form .btn-group-toggle > .btn-group > .search-submit input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.comment-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"],
.search-form .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* line 7, node_modules/bootstrap/scss/_input-group.scss */

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

/* line 14, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .form-control,
.comment-form .input-group > input[type="text"],
.comment-form .input-group > input[type="email"],
.comment-form .input-group > input[type="url"],
.comment-form .input-group > textarea,
.search-form .input-group > .search-field,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

/* line 25, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .form-control + .form-control,
.comment-form .input-group > input[type="text"] + .form-control,
.comment-form .input-group > input[type="email"] + .form-control,
.comment-form .input-group > input[type="url"] + .form-control,
.comment-form .input-group > textarea + .form-control,
.search-form .input-group > .search-field + .form-control,
.comment-form .input-group > .form-control + input[type="text"],
.comment-form .input-group > input[type="text"] + input[type="text"],
.comment-form .input-group > input[type="email"] + input[type="text"],
.comment-form .input-group > input[type="url"] + input[type="text"],
.comment-form .input-group > textarea + input[type="text"],
.search-form .comment-form .input-group > .search-field + input[type="text"],
.comment-form .search-form .input-group > .search-field + input[type="text"],
.comment-form .input-group > .form-control + input[type="email"],
.comment-form .input-group > input[type="text"] + input[type="email"],
.comment-form .input-group > input[type="email"] + input[type="email"],
.comment-form .input-group > input[type="url"] + input[type="email"],
.comment-form .input-group > textarea + input[type="email"],
.search-form .comment-form .input-group > .search-field + input[type="email"],
.comment-form .search-form .input-group > .search-field + input[type="email"],
.comment-form .input-group > .form-control + input[type="url"],
.comment-form .input-group > input[type="text"] + input[type="url"],
.comment-form .input-group > input[type="email"] + input[type="url"],
.comment-form .input-group > input[type="url"] + input[type="url"],
.comment-form .input-group > textarea + input[type="url"],
.search-form .comment-form .input-group > .search-field + input[type="url"],
.comment-form .search-form .input-group > .search-field + input[type="url"],
.comment-form .input-group > .form-control + textarea,
.comment-form .input-group > input[type="text"] + textarea,
.comment-form .input-group > input[type="email"] + textarea,
.comment-form .input-group > input[type="url"] + textarea,
.comment-form .input-group > textarea + textarea,
.search-form .comment-form .input-group > .search-field + textarea,
.comment-form .search-form .input-group > .search-field + textarea,
.search-form .input-group > .form-control + .search-field,
.comment-form .search-form .input-group > input[type="text"] + .search-field,
.search-form .comment-form .input-group > input[type="text"] + .search-field,
.comment-form .search-form .input-group > input[type="email"] + .search-field,
.search-form .comment-form .input-group > input[type="email"] + .search-field,
.comment-form .search-form .input-group > input[type="url"] + .search-field,
.search-form .comment-form .input-group > input[type="url"] + .search-field,
.comment-form .search-form .input-group > textarea + .search-field,
.search-form .comment-form .input-group > textarea + .search-field,
.search-form .input-group > .search-field + .search-field,
.input-group > .form-control + .custom-select,
.comment-form .input-group > input[type="text"] + .custom-select,
.comment-form .input-group > input[type="email"] + .custom-select,
.comment-form .input-group > input[type="url"] + .custom-select,
.comment-form .input-group > textarea + .custom-select,
.search-form .input-group > .search-field + .custom-select,
.input-group > .form-control + .custom-file,
.comment-form .input-group > input[type="text"] + .custom-file,
.comment-form .input-group > input[type="email"] + .custom-file,
.comment-form .input-group > input[type="url"] + .custom-file,
.comment-form .input-group > textarea + .custom-file,
.search-form .input-group > .search-field + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.comment-form .input-group > .form-control-plaintext + input[type="text"],
.comment-form .input-group > .form-control-plaintext + input[type="email"],
.comment-form .input-group > .form-control-plaintext + input[type="url"],
.comment-form .input-group > .form-control-plaintext + textarea,
.search-form .input-group > .form-control-plaintext + .search-field,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.comment-form .input-group > .custom-select + input[type="text"],
.comment-form .input-group > .custom-select + input[type="email"],
.comment-form .input-group > .custom-select + input[type="url"],
.comment-form .input-group > .custom-select + textarea,
.search-form .input-group > .custom-select + .search-field,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.comment-form .input-group > .custom-file + input[type="text"],
.comment-form .input-group > .custom-file + input[type="email"],
.comment-form .input-group > .custom-file + input[type="url"],
.comment-form .input-group > .custom-file + textarea,
.search-form .input-group > .custom-file + .search-field,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

/* line 33, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .form-control:focus,
.comment-form .input-group > input:focus[type="text"],
.comment-form .input-group > input:focus[type="email"],
.comment-form .input-group > input:focus[type="url"],
.comment-form .input-group > textarea:focus,
.search-form .input-group > .search-field:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

/* line 40, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

/* line 46, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .form-control:not(:last-child),
.comment-form .input-group > input:not(:last-child)[type="text"],
.comment-form .input-group > input:not(:last-child)[type="email"],
.comment-form .input-group > input:not(:last-child)[type="url"],
.comment-form .input-group > textarea:not(:last-child),
.search-form .input-group > .search-field:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 47, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .form-control:not(:first-child),
.comment-form .input-group > input:not(:first-child)[type="text"],
.comment-form .input-group > input:not(:first-child)[type="email"],
.comment-form .input-group > input:not(:first-child)[type="url"],
.comment-form .input-group > textarea:not(:first-child),
.search-form .input-group > .search-field:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 52, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

/* line 56, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 58, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 69, node_modules/bootstrap/scss/_input-group.scss */

.input-group-prepend,
.input-group-append {
  display: flex;
}

/* line 76, node_modules/bootstrap/scss/_input-group.scss */

.input-group-prepend .btn,
.input-group-prepend .comment-form input[type="submit"],
.comment-form .input-group-prepend input[type="submit"],
.input-group-prepend .search-form .search-submit,
.search-form .input-group-prepend .search-submit,
.input-group-append .btn,
.input-group-append .comment-form input[type="submit"],
.comment-form .input-group-append input[type="submit"],
.input-group-append .search-form .search-submit,
.search-form .input-group-append .search-submit {
  position: relative;
  z-index: 2;
}

/* line 80, node_modules/bootstrap/scss/_input-group.scss */

.input-group-prepend .btn:focus,
.input-group-prepend .comment-form input:focus[type="submit"],
.comment-form .input-group-prepend input:focus[type="submit"],
.input-group-prepend .search-form .search-submit:focus,
.search-form .input-group-prepend .search-submit:focus,
.input-group-append .btn:focus,
.input-group-append .comment-form input:focus[type="submit"],
.comment-form .input-group-append input:focus[type="submit"],
.input-group-append .search-form .search-submit:focus,
.search-form .input-group-append .search-submit:focus {
  z-index: 3;
}

/* line 85, node_modules/bootstrap/scss/_input-group.scss */

.input-group-prepend .btn + .btn,
.input-group-prepend .comment-form input[type="submit"] + .btn,
.comment-form .input-group-prepend input[type="submit"] + .btn,
.input-group-prepend .search-form .search-submit + .btn,
.search-form .input-group-prepend .search-submit + .btn,
.input-group-prepend .comment-form .btn + input[type="submit"],
.comment-form .input-group-prepend .btn + input[type="submit"],
.input-group-prepend .comment-form input[type="submit"] + input[type="submit"],
.comment-form .input-group-prepend input[type="submit"] + input[type="submit"],
.input-group-prepend .search-form .comment-form .search-submit + input[type="submit"],
.comment-form .input-group-prepend .search-form .search-submit + input[type="submit"],
.search-form .input-group-prepend .comment-form .search-submit + input[type="submit"],
.comment-form .search-form .input-group-prepend .search-submit + input[type="submit"],
.input-group-prepend .search-form .btn + .search-submit,
.search-form .input-group-prepend .btn + .search-submit,
.input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit,
.search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit,
.comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit,
.search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit,
.input-group-prepend .search-form .search-submit + .search-submit,
.search-form .input-group-prepend .search-submit + .search-submit,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .comment-form input[type="submit"] + .input-group-text,
.comment-form .input-group-prepend input[type="submit"] + .input-group-text,
.input-group-prepend .search-form .search-submit + .input-group-text,
.search-form .input-group-prepend .search-submit + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .comment-form .input-group-text + input[type="submit"],
.comment-form .input-group-prepend .input-group-text + input[type="submit"],
.input-group-prepend .search-form .input-group-text + .search-submit,
.search-form .input-group-prepend .input-group-text + .search-submit,
.input-group-append .btn + .btn,
.input-group-append .comment-form input[type="submit"] + .btn,
.comment-form .input-group-append input[type="submit"] + .btn,
.input-group-append .search-form .search-submit + .btn,
.search-form .input-group-append .search-submit + .btn,
.input-group-append .comment-form .btn + input[type="submit"],
.comment-form .input-group-append .btn + input[type="submit"],
.input-group-append .comment-form input[type="submit"] + input[type="submit"],
.comment-form .input-group-append input[type="submit"] + input[type="submit"],
.input-group-append .search-form .comment-form .search-submit + input[type="submit"],
.comment-form .input-group-append .search-form .search-submit + input[type="submit"],
.search-form .input-group-append .comment-form .search-submit + input[type="submit"],
.comment-form .search-form .input-group-append .search-submit + input[type="submit"],
.input-group-append .search-form .btn + .search-submit,
.search-form .input-group-append .btn + .search-submit,
.input-group-append .comment-form .search-form input[type="submit"] + .search-submit,
.search-form .input-group-append .comment-form input[type="submit"] + .search-submit,
.comment-form .input-group-append .search-form input[type="submit"] + .search-submit,
.search-form .comment-form .input-group-append input[type="submit"] + .search-submit,
.input-group-append .search-form .search-submit + .search-submit,
.search-form .input-group-append .search-submit + .search-submit,
.input-group-append .btn + .input-group-text,
.input-group-append .comment-form input[type="submit"] + .input-group-text,
.comment-form .input-group-append input[type="submit"] + .input-group-text,
.input-group-append .search-form .search-submit + .input-group-text,
.search-form .input-group-append .search-submit + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .comment-form .input-group-text + input[type="submit"],
.comment-form .input-group-append .input-group-text + input[type="submit"],
.input-group-append .search-form .input-group-text + .search-submit,
.search-form .input-group-append .input-group-text + .search-submit {
  margin-left: -1px;
}

/* line 93, node_modules/bootstrap/scss/_input-group.scss */

.input-group-prepend {
  margin-right: -1px;
}

/* line 94, node_modules/bootstrap/scss/_input-group.scss */

.input-group-append {
  margin-left: -1px;
}

/* line 102, node_modules/bootstrap/scss/_input-group.scss */

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* line 118, node_modules/bootstrap/scss/_input-group.scss */

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

/* line 130, node_modules/bootstrap/scss/_input-group.scss */

.input-group-lg > .form-control:not(textarea),
.comment-form .input-group-lg > input:not(textarea)[type="text"],
.comment-form .input-group-lg > input:not(textarea)[type="email"],
.comment-form .input-group-lg > input:not(textarea)[type="url"],
.comment-form .input-group-lg > textarea:not(textarea),
.search-form .input-group-lg > .search-field:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

/* line 135, node_modules/bootstrap/scss/_input-group.scss */

.input-group-lg > .form-control,
.comment-form .input-group-lg > input[type="text"],
.comment-form .input-group-lg > input[type="email"],
.comment-form .input-group-lg > input[type="url"],
.comment-form .input-group-lg > textarea,
.search-form .input-group-lg > .search-field,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.comment-form .input-group-lg > .input-group-prepend > input[type="submit"],
.search-form .input-group-lg > .input-group-prepend > .search-submit,
.input-group-lg > .input-group-append > .btn,
.comment-form .input-group-lg > .input-group-append > input[type="submit"],
.search-form .input-group-lg > .input-group-append > .search-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 147, node_modules/bootstrap/scss/_input-group.scss */

.input-group-sm > .form-control:not(textarea),
.comment-form .input-group-sm > input:not(textarea)[type="text"],
.comment-form .input-group-sm > input:not(textarea)[type="email"],
.comment-form .input-group-sm > input:not(textarea)[type="url"],
.comment-form .input-group-sm > textarea:not(textarea),
.search-form .input-group-sm > .search-field:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

/* line 152, node_modules/bootstrap/scss/_input-group.scss */

.input-group-sm > .form-control,
.comment-form .input-group-sm > input[type="text"],
.comment-form .input-group-sm > input[type="email"],
.comment-form .input-group-sm > input[type="url"],
.comment-form .input-group-sm > textarea,
.search-form .input-group-sm > .search-field,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.comment-form .input-group-sm > .input-group-prepend > input[type="submit"],
.search-form .input-group-sm > .input-group-prepend > .search-submit,
.input-group-sm > .input-group-append > .btn,
.comment-form .input-group-sm > .input-group-append > input[type="submit"],
.search-form .input-group-sm > .input-group-append > .search-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 164, node_modules/bootstrap/scss/_input-group.scss */

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

/* line 177, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .input-group-prepend > .btn,
.comment-form .input-group > .input-group-prepend > input[type="submit"],
.search-form .input-group > .input-group-prepend > .search-submit,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.comment-form .input-group > .input-group-append:not(:last-child) > input[type="submit"],
.search-form .input-group > .input-group-append:not(:last-child) > .search-submit,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.comment-form .input-group > .input-group-append:last-child > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.search-form .input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 186, node_modules/bootstrap/scss/_input-group.scss */

.input-group > .input-group-append > .btn,
.comment-form .input-group > .input-group-append > input[type="submit"],
.search-form .input-group > .input-group-append > .search-submit,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.comment-form .input-group > .input-group-prepend:not(:first-child) > input[type="submit"],
.search-form .input-group > .input-group-prepend:not(:first-child) > .search-submit,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.comment-form .input-group > .input-group-prepend:first-child > input:not(:first-child)[type="submit"],
.search-form .input-group > .input-group-prepend:first-child > .search-submit:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 10, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

/* line 17, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

/* line 22, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* line 27, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #438380;
  background-color: #438380;
}

/* line 34, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 43, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #85c1be;
}

/* line 47, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #a7d2d0;
  border-color: #a7d2d0;
}

/* line 55, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

/* line 58, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

/* line 69, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

/* line 75, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

/* line 90, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

/* line 108, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

/* line 113, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* line 119, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #438380;
  background-color: #438380;
}

/* line 124, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

/* line 130, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 131, 128, 0.5);
}

/* line 133, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(67, 131, 128, 0.5);
}

/* line 144, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

/* line 150, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/* line 156, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 131, 128, 0.5);
}

/* line 167, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-switch {
  padding-left: 2.25rem;
}

/* line 171, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

/* line 179, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 179, node_modules/bootstrap/scss/_custom-forms.scss */

  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

/* line 192, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

/* line 199, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 131, 128, 0.5);
}

/* line 212, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

/* line 230, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select:focus {
  border-color: #85c1be;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 239, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

/* line 250, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

/* line 257, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

/* line 263, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select::-ms-expand {
  display: none;
}

/* line 268, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

/* line 276, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

/* line 289, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

/* line 297, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

/* line 305, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-input:focus ~ .custom-file-label {
  border-color: #85c1be;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 310, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

/* line 315, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

/* line 320, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

/* line 325, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* line 342, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* line 366, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

/* line 373, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:focus {
  outline: none;
}

/* line 378, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 379, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 380, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 383, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-moz-focus-outer {
  border: 0;
}

/* line 387, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #438380;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 387, node_modules/bootstrap/scss/_custom-forms.scss */

  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

/* line 398, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-webkit-slider-thumb:active {
  background-color: #a7d2d0;
}

/* line 403, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

/* line 414, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #438380;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 414, node_modules/bootstrap/scss/_custom-forms.scss */

  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

/* line 424, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-moz-range-thumb:active {
  background-color: #a7d2d0;
}

/* line 429, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

/* line 440, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #438380;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 440, node_modules/bootstrap/scss/_custom-forms.scss */

  .custom-range::-ms-thumb {
    transition: none;
  }
}

/* line 453, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-ms-thumb:active {
  background-color: #a7d2d0;
}

/* line 458, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

/* line 469, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

/* line 474, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

/* line 481, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

/* line 485, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

/* line 489, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

/* line 493, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

/* line 497, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

/* line 503, node_modules/bootstrap/scss/_custom-forms.scss */

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 503, node_modules/bootstrap/scss/_custom-forms.scss */

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

/* line 6, node_modules/bootstrap/scss/_nav.scss */

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* line 14, node_modules/bootstrap/scss/_nav.scss */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

/* line 23, node_modules/bootstrap/scss/_nav.scss */

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

/* line 34, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

/* line 37, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

/* line 41, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

/* line 49, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

/* line 56, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

/* line 63, node_modules/bootstrap/scss/_nav.scss */

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 77, node_modules/bootstrap/scss/_nav.scss */

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

/* line 81, node_modules/bootstrap/scss/_nav.scss */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #438380;
}

/* line 94, node_modules/bootstrap/scss/_nav.scss */

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

/* line 101, node_modules/bootstrap/scss/_nav.scss */

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

/* line 114, node_modules/bootstrap/scss/_nav.scss */

.tab-content > .tab-pane {
  display: none;
}

/* line 117, node_modules/bootstrap/scss/_nav.scss */

.tab-content > .active {
  display: block;
}

/* line 18, node_modules/bootstrap/scss/_navbar.scss */

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

/* line 28, node_modules/bootstrap/scss/_navbar.scss */

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* line 42, node_modules/bootstrap/scss/_navbar.scss */

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

/* line 61, node_modules/bootstrap/scss/_navbar.scss */

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* line 68, node_modules/bootstrap/scss/_navbar.scss */

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

/* line 73, node_modules/bootstrap/scss/_navbar.scss */

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

/* line 84, node_modules/bootstrap/scss/_navbar.scss */

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* line 99, node_modules/bootstrap/scss/_navbar.scss */

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

/* line 108, node_modules/bootstrap/scss/_navbar.scss */

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

/* line 123, node_modules/bootstrap/scss/_navbar.scss */

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  /* line 142, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  /* line 140, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  /* line 153, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  /* line 156, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 160, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 167, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  /* line 172, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  /* line 179, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  /* line 142, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  /* line 140, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  /* line 153, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  /* line 156, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 160, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 167, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  /* line 172, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  /* line 179, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  /* line 142, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  /* line 140, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  /* line 153, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  /* line 156, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 160, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 167, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  /* line 172, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  /* line 179, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  /* line 142, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  /* line 140, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  /* line 153, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  /* line 156, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 160, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 167, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  /* line 172, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  /* line 179, node_modules/bootstrap/scss/_navbar.scss */

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

/* line 140, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

/* line 142, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

/* line 153, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand .navbar-nav {
  flex-direction: row;
}

/* line 156, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

/* line 160, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

/* line 167, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

/* line 172, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

/* line 179, node_modules/bootstrap/scss/_navbar.scss */

.navbar-expand .navbar-toggler {
  display: none;
}

/* line 194, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

/* line 203, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

/* line 210, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

/* line 215, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

/* line 223, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

/* line 228, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-toggler-icon {
  background-image: none;
}

/* line 232, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

/* line 234, node_modules/bootstrap/scss/_navbar.scss */

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

/* line 246, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-brand {
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

/* line 255, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

/* line 262, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

/* line 267, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

/* line 275, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

/* line 280, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-toggler-icon {
  background-image: none;
}

/* line 284, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/* line 286, node_modules/bootstrap/scss/_navbar.scss */

.navbar-dark .navbar-text a {
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

/* line 5, node_modules/bootstrap/scss/_card.scss */

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

/* line 16, node_modules/bootstrap/scss/_card.scss */

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

/* line 22, node_modules/bootstrap/scss/_card.scss */

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* line 28, node_modules/bootstrap/scss/_card.scss */

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* line 34, node_modules/bootstrap/scss/_card.scss */

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

/* line 42, node_modules/bootstrap/scss/_card.scss */

.card-title {
  margin-bottom: 0.75rem;
}

/* line 46, node_modules/bootstrap/scss/_card.scss */

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

/* line 51, node_modules/bootstrap/scss/_card.scss */

.card-text:last-child {
  margin-bottom: 0;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.card-link:hover {
  text-decoration: none;
}

/* line 60, node_modules/bootstrap/scss/_card.scss */

.card-link + .card-link {
  margin-left: 1.25rem;
}

/* line 69, node_modules/bootstrap/scss/_card.scss */

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/* line 76, node_modules/bootstrap/scss/_card.scss */

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

/* line 81, node_modules/bootstrap/scss/_card.scss */

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

/* line 87, node_modules/bootstrap/scss/_card.scss */

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

/* line 92, node_modules/bootstrap/scss/_card.scss */

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

/* line 102, node_modules/bootstrap/scss/_card.scss */

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

/* line 109, node_modules/bootstrap/scss/_card.scss */

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

/* line 115, node_modules/bootstrap/scss/_card.scss */

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

/* line 124, node_modules/bootstrap/scss/_card.scss */

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

/* line 130, node_modules/bootstrap/scss/_card.scss */

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

/* line 135, node_modules/bootstrap/scss/_card.scss */

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

/* line 143, node_modules/bootstrap/scss/_card.scss */

.card-deck {
  display: flex;
  flex-direction: column;
}

/* line 147, node_modules/bootstrap/scss/_card.scss */

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* line 143, node_modules/bootstrap/scss/_card.scss */

  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  /* line 156, node_modules/bootstrap/scss/_card.scss */

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

/* line 173, node_modules/bootstrap/scss/_card.scss */

.card-group {
  display: flex;
  flex-direction: column;
}

/* line 179, node_modules/bootstrap/scss/_card.scss */

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* line 173, node_modules/bootstrap/scss/_card.scss */

  .card-group {
    flex-flow: row wrap;
  }

  /* line 187, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  /* line 192, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  /* line 199, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* line 202, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  /* line 207, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  /* line 214, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* line 217, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  /* line 222, node_modules/bootstrap/scss/_card.scss */

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

/* line 239, node_modules/bootstrap/scss/_card.scss */

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  /* line 238, node_modules/bootstrap/scss/_card.scss */

  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  /* line 249, node_modules/bootstrap/scss/_card.scss */

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* line 262, node_modules/bootstrap/scss/_card.scss */

.accordion > .card {
  overflow: hidden;
}

/* line 266, node_modules/bootstrap/scss/_card.scss */

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

/* line 270, node_modules/bootstrap/scss/_card.scss */

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

/* line 276, node_modules/bootstrap/scss/_card.scss */

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 281, node_modules/bootstrap/scss/_card.scss */

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 285, node_modules/bootstrap/scss/_card.scss */

.accordion > .card .card-header {
  margin-bottom: -1px;
}

/* line 1, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

/* line 13, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

/* line 16, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

/* line 30, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

/* line 34, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

/* line 38, node_modules/bootstrap/scss/_breadcrumb.scss */

.breadcrumb-item.active {
  color: #6c757d;
}

/* line 1, node_modules/bootstrap/scss/_pagination.scss */

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

/* line 7, node_modules/bootstrap/scss/_pagination.scss */

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #438380;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

/* line 17, node_modules/bootstrap/scss/_pagination.scss */

.page-link:hover {
  z-index: 2;
  color: #29504f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/* line 25, node_modules/bootstrap/scss/_pagination.scss */

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.25);
}

/* line 34, node_modules/bootstrap/scss/_pagination.scss */

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* line 40, node_modules/bootstrap/scss/_pagination.scss */

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* line 45, node_modules/bootstrap/scss/_pagination.scss */

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 52, node_modules/bootstrap/scss/_pagination.scss */

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/* line 6, node_modules/bootstrap/scss/_badge.scss */

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 6, node_modules/bootstrap/scss/_badge.scss */

  .badge {
    transition: none;
  }
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

/* line 25, node_modules/bootstrap/scss/_badge.scss */

.badge:empty {
  display: none;
}

/* line 31, node_modules/bootstrap/scss/_badge.scss */

.btn .badge,
.comment-form input[type="submit"] .badge,
.search-form .search-submit .badge {
  position: relative;
  top: -1px;
}

/* line 40, node_modules/bootstrap/scss/_badge.scss */

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-primary {
  color: #fff;
  background-color: #438380;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #32615f;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 131, 128, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-success {
  color: #fff;
  background-color: #254846;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #142625;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 72, 70, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 1, node_modules/bootstrap/scss/_jumbotron.scss */

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */

  .jumbotron {
    padding: 4rem 2rem;
  }
}

/* line 13, node_modules/bootstrap/scss/_jumbotron.scss */

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/* line 5, node_modules/bootstrap/scss/_alert.scss */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/* line 14, node_modules/bootstrap/scss/_alert.scss */

.alert-heading {
  color: inherit;
}

/* line 20, node_modules/bootstrap/scss/_alert.scss */

.alert-link {
  font-weight: 700;
}

/* line 29, node_modules/bootstrap/scss/_alert.scss */

.alert-dismissible {
  padding-right: 4rem;
}

/* line 33, node_modules/bootstrap/scss/_alert.scss */

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-primary {
  color: #234443;
  background-color: #d9e6e6;
  border-color: #cadcdb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-primary hr {
  border-top-color: #bbd2d1;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-primary .alert-link {
  color: #122222;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-secondary .alert-link {
  color: #202326;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-success {
  color: #132524;
  background-color: #d3dada;
  border-color: #c2cccb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-success hr {
  border-top-color: #b4c0bf;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-success .alert-link {
  color: #020303;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-info hr {
  border-top-color: #abdde5;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-info .alert-link {
  color: #062c33;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-warning hr {
  border-top-color: #ffe8a1;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-warning .alert-link {
  color: #533f03;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-danger hr {
  border-top-color: #f1b0b7;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-danger .alert-link {
  color: #491217;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-light hr {
  border-top-color: #ececf6;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-light .alert-link {
  color: #686868;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-dark hr {
  border-top-color: #b9bbbe;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

/* line 9, node_modules/bootstrap/scss/_progress.scss */

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

/* line 19, node_modules/bootstrap/scss/_progress.scss */

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #438380;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 19, node_modules/bootstrap/scss/_progress.scss */

  .progress-bar {
    transition: none;
  }
}

/* line 30, node_modules/bootstrap/scss/_progress.scss */

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

/* line 36, node_modules/bootstrap/scss/_progress.scss */

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  /* line 36, node_modules/bootstrap/scss/_progress.scss */

  .progress-bar-animated {
    animation: none;
  }
}

/* line 1, node_modules/bootstrap/scss/_media.scss */

.media {
  display: flex;
  align-items: flex-start;
}

/* line 6, node_modules/bootstrap/scss/_media.scss */

.media-body {
  flex: 1;
}

/* line 5, node_modules/bootstrap/scss/_list-group.scss */

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

/* line 20, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* line 33, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

/* line 44, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

/* line 54, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* line 58, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* line 63, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

/* line 71, node_modules/bootstrap/scss/_list-group.scss */

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #438380;
  border-color: #438380;
}

/* line 88, node_modules/bootstrap/scss/_list-group.scss */

.list-group-horizontal {
  flex-direction: row;
}

/* line 91, node_modules/bootstrap/scss/_list-group.scss */

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

/* line 95, node_modules/bootstrap/scss/_list-group.scss */

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

/* line 100, node_modules/bootstrap/scss/_list-group.scss */

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  /* line 88, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-sm {
    flex-direction: row;
  }

  /* line 91, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  /* line 95, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  /* line 88, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-md {
    flex-direction: row;
  }

  /* line 91, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  /* line 95, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  /* line 88, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-lg {
    flex-direction: row;
  }

  /* line 91, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  /* line 95, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  /* line 88, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-xl {
    flex-direction: row;
  }

  /* line 91, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  /* line 95, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

/* line 117, node_modules/bootstrap/scss/_list-group.scss */

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

/* line 122, node_modules/bootstrap/scss/_list-group.scss */

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

/* line 128, node_modules/bootstrap/scss/_list-group.scss */

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

/* line 134, node_modules/bootstrap/scss/_list-group.scss */

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-primary {
  color: #234443;
  background-color: #cadcdb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #234443;
  background-color: #bbd2d1;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #234443;
  border-color: #234443;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-success {
  color: #132524;
  background-color: #c2cccb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #132524;
  background-color: #b4c0bf;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #132524;
  border-color: #132524;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

/* line 1, node_modules/bootstrap/scss/_close.scss */

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */

.close:hover {
  color: #000;
  text-decoration: none;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

/* line 29, node_modules/bootstrap/scss/_close.scss */

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

/* line 39, node_modules/bootstrap/scss/_close.scss */

a.close.disabled {
  pointer-events: none;
}

/* line 1, node_modules/bootstrap/scss/_toasts.scss */

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

/* line 14, node_modules/bootstrap/scss/_toasts.scss */

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

/* line 18, node_modules/bootstrap/scss/_toasts.scss */

.toast.showing {
  opacity: 1;
}

/* line 22, node_modules/bootstrap/scss/_toasts.scss */

.toast.show {
  display: block;
  opacity: 1;
}

/* line 27, node_modules/bootstrap/scss/_toasts.scss */

.toast.hide {
  display: none;
}

/* line 32, node_modules/bootstrap/scss/_toasts.scss */

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* line 42, node_modules/bootstrap/scss/_toasts.scss */

.toast-body {
  padding: 0.75rem;
}

/* line 7, node_modules/bootstrap/scss/_modal.scss */

.modal-open {
  overflow: hidden;
}

/* line 11, node_modules/bootstrap/scss/_modal.scss */

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* line 18, node_modules/bootstrap/scss/_modal.scss */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

/* line 36, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

/* line 44, node_modules/bootstrap/scss/_modal.scss */

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  /* line 44, node_modules/bootstrap/scss/_modal.scss */

  .modal.fade .modal-dialog {
    transition: none;
  }
}

/* line 48, node_modules/bootstrap/scss/_modal.scss */

.modal.show .modal-dialog {
  transform: none;
}

/* line 53, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

/* line 57, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

/* line 62, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

/* line 67, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

/* line 72, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

/* line 78, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

/* line 85, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* line 90, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

/* line 94, node_modules/bootstrap/scss/_modal.scss */

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

/* line 101, node_modules/bootstrap/scss/_modal.scss */

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/* line 119, node_modules/bootstrap/scss/_modal.scss */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

/* line 129, node_modules/bootstrap/scss/_modal.scss */

.modal-backdrop.fade {
  opacity: 0;
}

/* line 130, node_modules/bootstrap/scss/_modal.scss */

.modal-backdrop.show {
  opacity: 0.5;
}

/* line 135, node_modules/bootstrap/scss/_modal.scss */

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* line 143, node_modules/bootstrap/scss/_modal.scss */

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

/* line 151, node_modules/bootstrap/scss/_modal.scss */

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

/* line 158, node_modules/bootstrap/scss/_modal.scss */

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

/* line 167, node_modules/bootstrap/scss/_modal.scss */

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

/* line 176, node_modules/bootstrap/scss/_modal.scss */

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

/* line 177, node_modules/bootstrap/scss/_modal.scss */

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

/* line 181, node_modules/bootstrap/scss/_modal.scss */

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  /* line 192, node_modules/bootstrap/scss/_modal.scss */

  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  /* line 197, node_modules/bootstrap/scss/_modal.scss */

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  /* line 200, node_modules/bootstrap/scss/_modal.scss */

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  /* line 205, node_modules/bootstrap/scss/_modal.scss */

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  /* line 208, node_modules/bootstrap/scss/_modal.scss */

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  /* line 217, node_modules/bootstrap/scss/_modal.scss */

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  /* line 221, node_modules/bootstrap/scss/_modal.scss */

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  /* line 228, node_modules/bootstrap/scss/_modal.scss */

  .modal-xl {
    max-width: 1140px;
  }
}

/* line 2, node_modules/bootstrap/scss/_tooltip.scss */

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

/* line 15, node_modules/bootstrap/scss/_tooltip.scss */

.tooltip.show {
  opacity: 0.9;
}

/* line 17, node_modules/bootstrap/scss/_tooltip.scss */

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

/* line 23, node_modules/bootstrap/scss/_tooltip.scss */

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* line 32, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

/* line 35, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

/* line 38, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

/* line 46, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

/* line 49, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

/* line 54, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

/* line 62, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

/* line 65, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

/* line 68, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

/* line 76, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

/* line 79, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

/* line 84, node_modules/bootstrap/scss/_tooltip.scss */

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

/* line 108, node_modules/bootstrap/scss/_tooltip.scss */

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

/* line 1, node_modules/bootstrap/scss/_popover.scss */

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

/* line 20, node_modules/bootstrap/scss/_popover.scss */

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

/* line 27, node_modules/bootstrap/scss/_popover.scss */

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* line 38, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

/* line 41, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

/* line 44, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

/* line 50, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

/* line 58, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

/* line 61, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

/* line 67, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

/* line 73, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

/* line 81, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

/* line 84, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

/* line 87, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

/* line 93, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

/* line 101, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

/* line 113, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

/* line 116, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

/* line 122, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

/* line 128, node_modules/bootstrap/scss/_popover.scss */

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

/* line 153, node_modules/bootstrap/scss/_popover.scss */

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

/* line 163, node_modules/bootstrap/scss/_popover.scss */

.popover-header:empty {
  display: none;
}

/* line 168, node_modules/bootstrap/scss/_popover.scss */

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

/* line 14, node_modules/bootstrap/scss/_carousel.scss */

.carousel {
  position: relative;
}

/* line 18, node_modules/bootstrap/scss/_carousel.scss */

.carousel.pointer-event {
  touch-action: pan-y;
}

/* line 22, node_modules/bootstrap/scss/_carousel.scss */

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

/* line 29, node_modules/bootstrap/scss/_carousel.scss */

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 29, node_modules/bootstrap/scss/_carousel.scss */

  .carousel-item {
    transition: none;
  }
}

/* line 39, node_modules/bootstrap/scss/_carousel.scss */

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* line 45, node_modules/bootstrap/scss/_carousel.scss */

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

/* line 50, node_modules/bootstrap/scss/_carousel.scss */

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

/* line 61, node_modules/bootstrap/scss/_carousel.scss */

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

/* line 67, node_modules/bootstrap/scss/_carousel.scss */

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

/* line 74, node_modules/bootstrap/scss/_carousel.scss */

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  /* line 74, node_modules/bootstrap/scss/_carousel.scss */

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

/* line 87, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 87, node_modules/bootstrap/scss/_carousel.scss */

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

/* line 111, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-prev {
  left: 0;
}

/* line 117, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-next {
  right: 0;
}

/* line 125, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

/* line 132, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

/* line 135, node_modules/bootstrap/scss/_carousel.scss */

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

/* line 145, node_modules/bootstrap/scss/_carousel.scss */

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

/* line 159, node_modules/bootstrap/scss/_carousel.scss */

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 159, node_modules/bootstrap/scss/_carousel.scss */

  .carousel-indicators li {
    transition: none;
  }
}

/* line 177, node_modules/bootstrap/scss/_carousel.scss */

.carousel-indicators .active {
  opacity: 1;
}

/* line 187, node_modules/bootstrap/scss/_carousel.scss */

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* line 9, node_modules/bootstrap/scss/_spinners.scss */

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

/* line 21, node_modules/bootstrap/scss/_spinners.scss */

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

/* line 40, node_modules/bootstrap/scss/_spinners.scss */

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

/* line 52, node_modules/bootstrap/scss/_spinners.scss */

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

/* line 3, node_modules/bootstrap/scss/utilities/_align.scss */

.align-baseline {
  vertical-align: baseline !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_align.scss */

.align-top {
  vertical-align: top !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_align.scss */

.align-middle {
  vertical-align: middle !important;
}

/* line 6, node_modules/bootstrap/scss/utilities/_align.scss */

.align-bottom {
  vertical-align: bottom !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_align.scss */

.align-text-bottom {
  vertical-align: text-bottom !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_align.scss */

.align-text-top {
  vertical-align: text-top !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-primary {
  background-color: #438380 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #32615f !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-secondary {
  background-color: #6c757d !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-success {
  background-color: #254846 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #142625 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-info {
  background-color: #17a2b8 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-warning {
  background-color: #ffc107 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-danger {
  background-color: #dc3545 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-light {
  background-color: #f8f9fa !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */

.bg-dark {
  background-color: #343a40 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_background.scss */

.bg-white {
  background-color: #fff !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_background.scss */

.bg-transparent {
  background-color: transparent !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */

.border {
  border: 1px solid #dee2e6 !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

/* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

/* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-0 {
  border: 0 !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-top-0 {
  border-top: 0 !important;
}

/* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-right-0 {
  border-right: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-bottom-0 {
  border-bottom: 0 !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-left-0 {
  border-left: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-primary {
  border-color: #438380 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-secondary {
  border-color: #6c757d !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-success {
  border-color: #254846 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-info {
  border-color: #17a2b8 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-warning {
  border-color: #ffc107 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-danger {
  border-color: #dc3545 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-light {
  border-color: #f8f9fa !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-dark {
  border-color: #343a40 !important;
}

/* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */

.border-white {
  border-color: #fff !important;
}

/* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-sm {
  border-radius: 0.2rem !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded {
  border-radius: 0.25rem !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

/* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-lg {
  border-radius: 0.3rem !important;
}

/* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-circle {
  border-radius: 50% !important;
}

/* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-pill {
  border-radius: 50rem !important;
}

/* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */

.rounded-0 {
  border-radius: 0 !important;
}

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-none {
  display: none !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-inline {
  display: inline !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-inline-block {
  display: inline-block !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-block {
  display: block !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-table {
  display: table !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-table-row {
  display: table-row !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-table-cell {
  display: table-cell !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-flex {
  display: flex !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-flex {
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-flex {
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-flex {
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-flex {
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-none {
    display: none !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-inline {
    display: inline !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-inline-block {
    display: inline-block !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-block {
    display: block !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-table {
    display: table !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-table-row {
    display: table-row !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-table-cell {
    display: table-cell !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-flex {
    display: flex !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

/* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

/* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive::before {
  display: block;
  content: "";
}

/* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */

.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-row {
  flex-direction: row !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-column {
  flex-direction: column !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-wrap {
  flex-wrap: wrap !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-nowrap {
  flex-wrap: nowrap !important;
}

/* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

/* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-fill {
  flex: 1 1 auto !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-grow-0 {
  flex-grow: 0 !important;
}

/* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-grow-1 {
  flex-grow: 1 !important;
}

/* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

/* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

/* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

.justify-content-start {
  justify-content: flex-start !important;
}

/* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

.justify-content-end {
  justify-content: flex-end !important;
}

/* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

.justify-content-center {
  justify-content: center !important;
}

/* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

.justify-content-between {
  justify-content: space-between !important;
}

/* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

.justify-content-around {
  justify-content: space-around !important;
}

/* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-items-start {
  align-items: flex-start !important;
}

/* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-items-end {
  align-items: flex-end !important;
}

/* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-items-center {
  align-items: center !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-items-baseline {
  align-items: baseline !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-items-stretch {
  align-items: stretch !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-start {
  align-content: flex-start !important;
}

/* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-end {
  align-content: flex-end !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-center {
  align-content: center !important;
}

/* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-between {
  align-content: space-between !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-around {
  align-content: space-around !important;
}

/* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-content-stretch {
  align-content: stretch !important;
}

/* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-auto {
  align-self: auto !important;
}

/* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-start {
  align-self: flex-start !important;
}

/* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-end {
  align-self: flex-end !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-center {
  align-self: center !important;
}

/* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-baseline {
  align-self: baseline !important;
}

/* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-row {
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-column {
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-sm-center {
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-sm-center {
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-center {
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-between {
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-around {
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-auto {
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-center {
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-row {
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-column {
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-md-center {
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-md-start {
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-md-end {
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-md-center {
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-start {
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-end {
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-center {
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-between {
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-around {
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-auto {
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-start {
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-end {
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-center {
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-row {
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-column {
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-lg-center {
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-lg-center {
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-center {
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-between {
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-around {
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-auto {
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-center {
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-row {
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-column {
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-xl-center {
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-xl-center {
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-center {
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-between {
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-around {
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-auto {
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-center {
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

/* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

.float-left {
  float: left !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

.float-right {
  float: right !important;
}

/* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-sm-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-sm-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-md-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-md-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-lg-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-lg-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-xl-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-xl-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */

  .float-xl-none {
    float: none !important;
  }
}

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */

.overflow-auto {
  overflow: auto !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */

.overflow-hidden {
  overflow: hidden !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

.position-static {
  position: static !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

.position-relative {
  position: relative !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

.position-absolute {
  position: absolute !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

.position-fixed {
  position: fixed !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */

.position-sticky {
  position: sticky !important;
}

/* line 10, node_modules/bootstrap/scss/utilities/_position.scss */

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* line 18, node_modules/bootstrap/scss/utilities/_position.scss */

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */

  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

/* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */

.sr-only,
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* line 24, node_modules/bootstrap/scss/mixins/_screen-reader.scss */

.sr-only-focusable:active,
.screen-reader-text:active,
.sr-only-focusable:focus,
.screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */

.shadow-none {
  box-shadow: none !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.w-25 {
  width: 25% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.w-50 {
  width: 50% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.w-75 {
  width: 75% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.w-100 {
  width: 100% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.w-auto {
  width: auto !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.h-25 {
  height: 25% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.h-50 {
  height: 50% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.h-75 {
  height: 75% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.h-100 {
  height: 100% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */

.h-auto {
  height: auto !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */

.mw-100 {
  max-width: 100% !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */

.mh-100 {
  max-height: 100% !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */

.min-vw-100 {
  min-width: 100vw !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */

.min-vh-100 {
  min-height: 100vh !important;
}

/* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */

.vw-100 {
  width: 100vw !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */

.vh-100 {
  height: 100vh !important;
}

/* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-0 {
  margin: 0 !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-1 {
  margin: 0.25rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-2 {
  margin: 0.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-3 {
  margin: 1rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-4 {
  margin: 1.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-5 {
  margin: 3rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-0 {
  padding: 0 !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-1 {
  padding: 0.25rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-2 {
  padding: 0.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-3 {
  padding: 1rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-4 {
  padding: 1.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

.p-5 {
  padding: 3rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-n1 {
  margin: -0.25rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-n2 {
  margin: -0.5rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-n3 {
  margin: -1rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-n4 {
  margin: -1.5rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-n5 {
  margin: -3rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

/* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

.m-auto {
  margin: auto !important;
}

/* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

/* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

/* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

/* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-sm-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-sm-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-md-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-md-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-lg-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-lg-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .p-xl-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .m-xl-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* line 7, node_modules/bootstrap/scss/utilities/_text.scss */

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_text.scss */

.text-justify {
  text-align: justify !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_text.scss */

.text-wrap {
  white-space: normal !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_text.scss */

.text-nowrap {
  white-space: nowrap !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_text.scss */

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

.text-left {
  text-align: left !important;
}

/* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

.text-right {
  text-align: right !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-sm-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-sm-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-md-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-md-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-lg-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-lg-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-xl-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-xl-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */

  .text-xl-center {
    text-align: center !important;
  }
}

/* line 30, node_modules/bootstrap/scss/utilities/_text.scss */

.text-lowercase {
  text-transform: lowercase !important;
}

/* line 31, node_modules/bootstrap/scss/utilities/_text.scss */

.text-uppercase {
  text-transform: uppercase !important;
}

/* line 32, node_modules/bootstrap/scss/utilities/_text.scss */

.text-capitalize {
  text-transform: capitalize !important;
}

/* line 36, node_modules/bootstrap/scss/utilities/_text.scss */

.font-weight-light {
  font-weight: 300 !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_text.scss */

.font-weight-lighter {
  font-weight: lighter !important;
}

/* line 38, node_modules/bootstrap/scss/utilities/_text.scss */

.font-weight-normal {
  font-weight: 400 !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_text.scss */

.font-weight-bold {
  font-weight: 700 !important;
}

/* line 40, node_modules/bootstrap/scss/utilities/_text.scss */

.font-weight-bolder {
  font-weight: bolder !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_text.scss */

.font-italic {
  font-style: italic !important;
}

/* line 45, node_modules/bootstrap/scss/utilities/_text.scss */

.text-white {
  color: #fff !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-primary {
  color: #438380 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-primary:hover,
a.text-primary:focus {
  color: #29504f !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-secondary {
  color: #6c757d !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-success {
  color: #254846 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-success:hover,
a.text-success:focus {
  color: #0b1515 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-info {
  color: #17a2b8 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-warning {
  color: #ffc107 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-danger {
  color: #dc3545 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-light {
  color: #f8f9fa !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */

.text-dark {
  color: #343a40 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

/* line 51, node_modules/bootstrap/scss/utilities/_text.scss */

.text-body {
  color: #212529 !important;
}

/* line 52, node_modules/bootstrap/scss/utilities/_text.scss */

.text-muted {
  color: #6c757d !important;
}

/* line 54, node_modules/bootstrap/scss/utilities/_text.scss */

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

/* line 55, node_modules/bootstrap/scss/utilities/_text.scss */

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* line 59, node_modules/bootstrap/scss/utilities/_text.scss */

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/* line 63, node_modules/bootstrap/scss/utilities/_text.scss */

.text-decoration-none {
  text-decoration: none !important;
}

/* line 65, node_modules/bootstrap/scss/utilities/_text.scss */

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

/* line 72, node_modules/bootstrap/scss/utilities/_text.scss */

.text-reset {
  color: inherit !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */

.visible {
  visibility: visible !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */

.invisible {
  visibility: hidden !important;
}

@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  /* line 24, node_modules/bootstrap/scss/_print.scss */

  a:not(.btn) {
    text-decoration: underline;
  }

  /* line 34, node_modules/bootstrap/scss/_print.scss */

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /* line 49, node_modules/bootstrap/scss/_print.scss */

  pre {
    white-space: pre-wrap !important;
  }

  /* line 52, node_modules/bootstrap/scss/_print.scss */

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  /* line 63, node_modules/bootstrap/scss/_print.scss */

  thead {
    display: table-header-group;
  }

  /* line 67, node_modules/bootstrap/scss/_print.scss */

  tr,
  img {
    page-break-inside: avoid;
  }

  /* line 72, node_modules/bootstrap/scss/_print.scss */

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  /* line 79, node_modules/bootstrap/scss/_print.scss */

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  /* line 92, node_modules/bootstrap/scss/_print.scss */

  body {
    min-width: 992px !important;
  }

  /* line 95, node_modules/bootstrap/scss/_print.scss */

  .container {
    min-width: 992px !important;
  }

  /* line 100, node_modules/bootstrap/scss/_print.scss */

  .navbar {
    display: none;
  }

  /* line 103, node_modules/bootstrap/scss/_print.scss */

  .badge {
    border: 1px solid #000;
  }

  /* line 107, node_modules/bootstrap/scss/_print.scss */

  .table {
    border-collapse: collapse !important;
  }

  /* line 110, node_modules/bootstrap/scss/_print.scss */

  .table td,
  .table th {
    background-color: #fff !important;
  }

  /* line 117, node_modules/bootstrap/scss/_print.scss */

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  /* line 123, node_modules/bootstrap/scss/_print.scss */

  .table-dark {
    color: inherit;
  }

  /* line 126, node_modules/bootstrap/scss/_print.scss */

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  /* line 134, node_modules/bootstrap/scss/_print.scss */

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */

/** Import theme styles */

/* line 1, resources/assets/styles/common/_global.scss */

body,
html {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  color: #54585b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 9, resources/assets/styles/common/_global.scss */

body h1,
body span.h1,
html h1,
html span.h1 {
  font-size: 35px;
}

/* line 13, resources/assets/styles/common/_global.scss */

body h2,
body span.h2,
html h2,
html span.h2 {
  font-size: 28px;
}

/* line 17, resources/assets/styles/common/_global.scss */

body h3,
body span.h3,
html h3,
html span.h3 {
  font-size: 26px;
}

/* line 22, resources/assets/styles/common/_global.scss */

body p.big,
html p.big {
  font-size: 20px;
}

/* line 25, resources/assets/styles/common/_global.scss */

body p,
body p.med,
html p,
html p.med {
  font-size: 15px;
}

/* line 29, resources/assets/styles/common/_global.scss */

body p.small,
html p.small {
  font-size: 12px;
}

/* line 33, resources/assets/styles/common/_global.scss */

body a,
html a {
  color: #438380;
}

/* line 38, resources/assets/styles/common/_global.scss */

.row-gap {
  row-gap: 1.5rem;
}

/* line 42, resources/assets/styles/common/_global.scss */

.vertical-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 51, resources/assets/styles/common/_global.scss */

.text-center {
  text-align: center;
}

/* line 59, resources/assets/styles/common/_global.scss */

.read-more {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #438380;
  text-decoration: none;
  transition: all ease-in-out 200ms;
}

/* line 68, resources/assets/styles/common/_global.scss */

.read-more:hover {
  text-decoration: none;
  color: #29504f;
}

/* line 73, resources/assets/styles/common/_global.scss */

.read-more svg {
  font-size: 0.95rem;
  margin-left: 0.5rem;
}

/* line 79, resources/assets/styles/common/_global.scss */

ul.reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 85, resources/assets/styles/common/_global.scss */

.button-bar {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: 991px) {
  /* line 85, resources/assets/styles/common/_global.scss */

  .button-bar {
    flex-direction: column;
  }
}

/* line 1, resources/assets/styles/components/_buttons.scss */

.button {
  display: inline-block;
  background-color: #438380;
  color: #fff;
  min-width: 140px;
  padding: 12px 20px;
  border: 2px solid #438380;
  border-radius: 8px;
  font-size: 17px;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 200ms;
  font-weight: 500;
  letter-spacing: 0.2px;
}

/* line 16, resources/assets/styles/components/_buttons.scss */

.button:hover {
  background: #3c7673;
  border-color: #3c7673;
  color: #fff;
  text-decoration: none;
}

/* line 23, resources/assets/styles/components/_buttons.scss */

.button--secondary {
  background-color: #fff;
  color: #52565e;
}

/* line 27, resources/assets/styles/components/_buttons.scss */

.button--secondary:hover {
  background-color: whitesmoke;
  color: #52565e;
}

/* line 33, resources/assets/styles/components/_buttons.scss */

.button--inverted {
  background-color: transparent;
  border-color: #fff;
}

/* line 37, resources/assets/styles/components/_buttons.scss */

.button--inverted:hover {
  background-color: #3c7673;
  border-color: #fff;
}

/* line 43, resources/assets/styles/components/_buttons.scss */

.button--transparent {
  background-color: transparent;
  border-color: transparent;
}

/* line 47, resources/assets/styles/components/_buttons.scss */

.button--transparent:hover {
  background-color: transparent;
  border-color: transparent;
}

/* line 54, resources/assets/styles/components/_buttons.scss */

.btn-box {
  margin-top: 16px;
  float: left;
  width: 100%;
}

/* line 59, resources/assets/styles/components/_buttons.scss */

.btn-box.btn-center {
  text-align: center;
}

/* line 64, resources/assets/styles/components/_buttons.scss */

.connected_klachten .button {
  font-weight: normal;
  padding: 12px 15px 9px;
  min-width: auto;
  background: #4a908d !important;
}

/* line 69, resources/assets/styles/components/_buttons.scss */

.connected_klachten .button:hover {
  background: #438380 !important;
}

/* line 74, resources/assets/styles/components/_buttons.scss */

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: none;
}

/* line 5, resources/assets/styles/components/_comments.scss */

.comment-list ol {
  list-style: none;
}

/** Search form */

/* line 6, resources/assets/styles/components/_forms.scss */

.search-form label {
  font-weight: normal;
}

/* line 23, resources/assets/styles/components/_forms.scss */

.wpcf7 label {
  font-weight: bold;
}

/* line 26, resources/assets/styles/components/_forms.scss */

.wpcf7 .wpcf7-form-control-wrap {
  font-weight: normal;
  display: inline-block;
  width: 100%;
  padding: 4px 0;
}

/* line 31, resources/assets/styles/components/_forms.scss */

.wpcf7 .wpcf7-form-control-wrap input,
.wpcf7 .wpcf7-form-control-wrap textarea {
  width: 100%;
  border: 1px solid #c1e0dc;
  padding: 14px 16px 8px;
  border-radius: 0;
  outline: none;
  resize: none;
}

/* line 38, resources/assets/styles/components/_forms.scss */

.wpcf7 .wpcf7-form-control-wrap input:focus,
.wpcf7 .wpcf7-form-control-wrap textarea:focus {
  border-color: #84c2b9;
}

/* line 42, resources/assets/styles/components/_forms.scss */

.wpcf7 .wpcf7-form-control-wrap input[type="radio"] {
  width: auto;
  margin-right: 5px;
}

/* line 47, resources/assets/styles/components/_forms.scss */

.wpcf7 .wpcf7-form-control-wrap select {
  outline: none;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  padding-top: 10px;
  padding-right: calc(10% + 50px);
  padding-bottom: 10px;
  padding-left: 12px;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  background: none;
  -webkit-appearance: none;
}

/* line 68, resources/assets/styles/components/_forms.scss */

.wpcf7 .kind_geslacht {
  background: #fff;
  border: 1px solid #c1e0dc;
}

/* line 73, resources/assets/styles/components/_forms.scss */

.wpcf7 .button {
  border: none;
  outline: none;
}

/* line 78, resources/assets/styles/components/_forms.scss */

.wpcf7 p {
  margin-bottom: 0.5rem !important;
}

/* line 82, resources/assets/styles/components/_forms.scss */

.wpcf7 label,
.wpcf7 span.wpcf7-list-item {
  width: 100%;
  margin: 0;
}

/* line 86, resources/assets/styles/components/_forms.scss */

.wpcf7 label input[type='checkbox'],
.wpcf7 span.wpcf7-list-item input[type='checkbox'] {
  width: initial;
}

/* line 92, resources/assets/styles/components/_forms.scss */

.wpcf7 .aanhef span.wpcf7-list-item {
  width: initial;
  margin-right: 8px;
}

/* line 96, resources/assets/styles/components/_forms.scss */

.wpcf7 .aanhef span.wpcf7-list-item input {
  margin: 3px 8px 0 0;
  float: left;
  width: initial;
}

/* line 107, resources/assets/styles/components/_forms.scss */

.gform_wrapper label {
  font-weight: bold;
}

/* line 110, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container {
  font-weight: normal;
  display: inline-block;
  width: 100%;
  padding: 4px 0;
}

/* line 115, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container input,
.gform_wrapper .ginput_container textarea {
  width: 100%;
  border: 1px solid #c1e0dc;
  padding: 14px 16px 8px;
  border-radius: 0;
  outline: none;
  resize: none;
}

/* line 122, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container input:focus,
.gform_wrapper .ginput_container textarea:focus {
  border-color: #84c2b9;
}

/* line 126, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container input[type="radio"] {
  width: auto;
  margin-right: 5px;
}

/* line 130, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container input[type="checkbox"] {
  width: auto;
  padding: 0;
}

/* line 135, resources/assets/styles/components/_forms.scss */

.gform_wrapper .ginput_container select {
  outline: none;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 14px 16px 8px;
  border-radius: 0;
  border: 1px solid #c1e0dc;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  -webkit-appearance: none;
}

/* line 153, resources/assets/styles/components/_forms.scss */

.gform_wrapper .button {
  border: none;
  outline: none;
}

/* line 158, resources/assets/styles/components/_forms.scss */

.gform_wrapper p {
  margin-bottom: 0.5rem !important;
}

/* line 164, resources/assets/styles/components/_forms.scss */

table {
  font-size: 90%;
}

/* line 166, resources/assets/styles/components/_forms.scss */

table thead,
table tbody {
  background: #f8fbfb;
}

/* line 169, resources/assets/styles/components/_forms.scss */

table th,
table td {
  border-color: #c1e0dc !important;
}

/* line 173, resources/assets/styles/components/_forms.scss */

table thead tr th {
  font-weight: normal;
  font-style: italic;
}

/* line 177, resources/assets/styles/components/_forms.scss */

table thead tr th:first-child {
  font-weight: bold;
  font-size: normal;
}

/* line 183, resources/assets/styles/components/_forms.scss */

table tr td:first-child {
  font-weight: bold;
}

@media (min-width: 768px) {
  /* line 188, resources/assets/styles/components/_forms.scss */

  table.table-responsive {
    display: inline-table;
  }
}

/* line 194, resources/assets/styles/components/_forms.scss */

ol {
  font-size: 80%;
  padding-left: 20px;
  font-style: italic;
  margin-bottom: 3rem;
}

/* line 3, resources/assets/styles/components/_faq.scss */

.faq #accordion .card {
  border: none;
  background: none;
  margin-bottom: .5em;
  padding: 0;
}

/* line 8, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header {
  background-color: none;
  padding: 0;
  border: none;
}

/* line 12, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn,
.faq #accordion .card .card-header .comment-form input[type="submit"],
.comment-form .faq #accordion .card .card-header input[type="submit"],
.faq #accordion .card .card-header .search-form .search-submit,
.search-form .faq #accordion .card .card-header .search-submit {
  background-color: #faf7f4;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  padding: .75rem 1rem;
}

/* line 24, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn svg,
.faq #accordion .card .card-header .comment-form input[type="submit"] svg,
.comment-form .faq #accordion .card .card-header input[type="submit"] svg,
.faq #accordion .card .card-header .search-form .search-submit svg,
.search-form .faq #accordion .card .card-header .search-submit svg {
  color: #438380;
  position: absolute;
  font-size: 1.4rem;
  right: 15px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
}

/* line 35, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn:hover,
.faq #accordion .card .card-header .comment-form input:hover[type="submit"],
.comment-form .faq #accordion .card .card-header input:hover[type="submit"],
.faq #accordion .card .card-header .search-form .search-submit:hover,
.search-form .faq #accordion .card .card-header .search-submit:hover {
  text-decoration: none;
  color: #fff;
  background-color: #438380;
}

/* line 39, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn:hover svg,
.faq #accordion .card .card-header .comment-form input:hover[type="submit"] svg,
.comment-form .faq #accordion .card .card-header input:hover[type="submit"] svg,
.faq #accordion .card .card-header .search-form .search-submit:hover svg,
.search-form .faq #accordion .card .card-header .search-submit:hover svg {
  color: #fff;
}

/* line 43, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn[aria-expanded=true],
.faq #accordion .card .card-header .comment-form input[aria-expanded=true][type="submit"],
.comment-form .faq #accordion .card .card-header input[aria-expanded=true][type="submit"],
.faq #accordion .card .card-header .search-form .search-submit[aria-expanded=true],
.search-form .faq #accordion .card .card-header .search-submit[aria-expanded=true] {
  text-decoration: none;
  color: #fff;
  background-color: #438380;
}

/* line 47, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn[aria-expanded=true] svg,
.faq #accordion .card .card-header .comment-form input[aria-expanded=true][type="submit"] svg,
.comment-form .faq #accordion .card .card-header input[aria-expanded=true][type="submit"] svg,
.faq #accordion .card .card-header .search-form .search-submit[aria-expanded=true] svg,
.search-form .faq #accordion .card .card-header .search-submit[aria-expanded=true] svg {
  color: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 53, resources/assets/styles/components/_faq.scss */

.faq #accordion .card .card-header .btn:focus,
.faq #accordion .card .card-header .comment-form input:focus[type="submit"],
.comment-form .faq #accordion .card .card-header input:focus[type="submit"],
.faq #accordion .card .card-header .search-form .search-submit:focus,
.search-form .faq #accordion .card .card-header .search-submit:focus {
  text-decoration: none;
}

/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */

/* line 7, resources/assets/styles/components/_wp-classes.scss */

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

/* line 14, resources/assets/styles/components/_wp-classes.scss */

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto;
}

/* line 20, resources/assets/styles/components/_wp-classes.scss */

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto;
}

@media (min-width: 576px) {
  /* line 27, resources/assets/styles/components/_wp-classes.scss */

  .alignleft {
    float: left;
    margin-right: 0.5rem;
  }

  /* line 32, resources/assets/styles/components/_wp-classes.scss */

  .alignright {
    float: right;
    margin-left: 0.5rem;
  }
}

/** Captions */

/** Text meant only for screen readers */

/* line 2, resources/assets/styles/layouts/_header.scss */

header.header .top-header {
  background: #f1f8f7;
  width: 100%;
  display: flex;
}

/* line 8, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* line 17, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li a {
  color: #52565e;
  font-size: 0.8em;
  padding: 10px 14px 8px;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.2px;
  transition: all ease-in-out 200ms;
}

/* line 26, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li a:hover {
  background: #e3f1ef;
  text-decoration: none;
}

/* line 31, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li a.phone {
  background: #438380;
  color: #fff;
  margin-left: 7px;
}

@media (max-width: 575px) {
  /* line 31, resources/assets/styles/layouts/_header.scss */

  header.header .top-header nav ul li a.phone {
    display: none;
  }
}

/* line 40, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li a.phone:hover {
  background: #3c7673;
}

/* line 46, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches {
  float: left;
}

/* line 50, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches.open a {
  background: #e3f1ef;
}

/* line 54, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches a {
  text-decoration: none;
}

/* line 57, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches .dropdown-menu {
  color: #52565e;
  border-color: #f1f8f7;
  margin-top: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

/* line 63, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches .dropdown-menu a {
  color: #6a6f79;
  transition: all ease-in-out 200ms;
}

/* line 67, resources/assets/styles/layouts/_header.scss */

header.header .top-header nav ul li .branches .dropdown-menu a:hover {
  background: #eff6f5;
}

@media (max-width: 1199px) {
  /* line 78, resources/assets/styles/layouts/_header.scss */

  header.header .top-header .container {
    max-width: 100%;
  }
}

/* line 84, resources/assets/styles/layouts/_header.scss */

header.header .main-header {
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

@media (max-width: 1199px) {
  /* line 92, resources/assets/styles/layouts/_header.scss */

  header.header .main-header .container {
    max-width: 100%;
  }
}

/* line 97, resources/assets/styles/layouts/_header.scss */

header.header .main-header .logo {
  display: inline-block;
  padding: 10px 0;
  float: left;
}

/* line 102, resources/assets/styles/layouts/_header.scss */

header.header .main-header .logo img {
  height: 70px;
  width: auto;
}

@media (max-width: 991px) {
  /* line 102, resources/assets/styles/layouts/_header.scss */

  header.header .main-header .logo img {
    height: 40px;
  }
}

/* line 112, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation {
  float: right;
  padding: 8px 0;
}

@media (max-width: 991px) {
  /* line 112, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation {
    display: none;
    top: 60px;
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    padding: 0;
  }

  /* line 127, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul {
    padding: 16px 0 !important;
  }

  /* line 130, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li {
    text-align: left !important;
    float: none !important;
  }

  /* line 134, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li a {
    padding: 16px 20px !important;
  }

  /* line 140, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation.active {
    display: block;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  }
}

/* line 146, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul {
  padding: 0;
  margin: 0;
}

/* line 150, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li {
  float: left;
  list-style-type: none;
  padding: 25px 0;
  text-align: center;
  position: relative;
}

/* line 157, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li.active a {
  color: #438380;
}

@media (max-width: 1199px) {
  /* line 162, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li ul {
    display: none;
  }
}

@media (max-width: 991px) {
  /* line 150, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li {
    padding: 0;
    width: 100%;
  }
}

/* line 172, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li a {
  color: #52565e;
  padding: 25px 20px;
  font-size: 17px;
  font-weight: 600;
  transition: all ease-in-out 200ms;
}

@media (max-width: 1199px) {
  /* line 172, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li a {
    font-size: 15px;
    letter-spacing: -0.15px;
  }
}

@media (max-width: 991px) {
  /* line 172, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li a {
    display: block;
    font-size: 18px;
    letter-spacing: 0;
    width: 100%;
  }
}

/* line 191, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li a:hover {
  color: #438380;
  text-decoration: none;
  transition: all 0.3s;
}

@media (min-width: 1200px) {
  /* line 200, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li.menu-item-has-children > a {
    padding-bottom: 60px;
  }

  /* line 203, resources/assets/styles/layouts/_header.scss */

  header.header .main-header nav.navigation ul li.menu-item-has-children > a:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    color: #6a6f79;
  }
}

/* line 219, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(58px);
}

/* line 225, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li ul {
  position: absolute;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  text-align: left;
  background: #fff;
  transform: translateY(38px);
  padding: 13.6px 0;
  padding: 0.85rem 0;
  transition: all 0.15s ease-out;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* line 240, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li ul li {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0;
}

/* line 246, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li ul li a {
  display: block;
  padding: 12px 24px;
}

/* line 250, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li ul li a:hover {
  background: #f4faf9;
}

/* line 257, resources/assets/styles/layouts/_header.scss */

header.header .main-header nav.navigation ul li ul:before {
  content: ' ';
  position: absolute;
  top: -8px;
  top: -0.5rem;
  left: calc(50% - 0.6rem);
  border-top: 0;
  border-left: 9.6px solid transparent;
  border-left: 0.6rem solid transparent;
  border-right: 9.6px solid transparent;
  border-right: 0.6rem solid transparent;
  border-bottom: 9.6px solid #fff;
  border-bottom: 0.6rem solid #fff;
}

/* line 276, resources/assets/styles/layouts/_header.scss */

header.header .main-header .navigation--icon {
  display: block;
  margin: 5px;
  height: 50px;
  float: right;
  padding: 13px 0;
  font-weight: bold;
  line-height: 2.25;
  text-transform: uppercase;
  font-size: 80%;
  user-select: none;
}

/* line 288, resources/assets/styles/layouts/_header.scss */

header.header .main-header .navigation--icon:after {
  content: '';
  display: block;
  background-image: url(../images/icon-navigation.svg);
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  float: right;
  margin-left: 14px;
}

@media (min-width: 992px) {
  /* line 276, resources/assets/styles/layouts/_header.scss */

  header.header .main-header .navigation--icon {
    display: none;
  }
}

/* line 1, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice {
  float: left;
  margin-top: -140px;
  background: #438380;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: #ebebeb;
  overflow: hidden;
}

@media (max-width: 1199px) {
  /* line 1, resources/assets/styles/layouts/_sidebar.scss */

  .sidebar.advice {
    margin-top: 1rem;
  }
}

/* line 14, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice .sidebarinner {
  float: left;
  padding: 24px 12px 0;
  text-align: center;
}

/* line 19, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice .sidebarinner h3 {
  font-weight: bold;
  text-align: center;
}

/* line 23, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice .sidebarinner p {
  font-size: 16px;
  margin-bottom: 0;
}

/* line 27, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice .sidebarinner .customer-service {
  float: left;
  width: 100%;
  padding: 14px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

/* line 35, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice .sidebarinner .customer-service span {
  float: left;
  width: 100%;
  font-size: 27px;
}

/* line 42, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice a {
  background: #f99d19;
  float: left;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  color: whitesmoke;
  padding: 16px 0 15px;
  text-align: center;
  transition: all 0.2s;
  text-decoration: none;
}

/* line 54, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.advice a:hover {
  background: #faa123;
}

/* line 61, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list span.read-more {
  user-select: none;
  display: none;
  transition: all ease-in-out 200ms;
}

/* line 66, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list span.read-more:after {
  content: '';
  display: block;
  background-image: url(../images/read-more-icon.svg);
  background-size: 22px 22px;
  height: 22px;
  width: 22px;
  float: right;
}

/* line 76, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list span.read-more.active {
  background: #3c7673;
}

/* line 78, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list span.read-more.active:after {
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  /* line 87, resources/assets/styles/layouts/_sidebar.scss */

  .sidebar.list span.read-more {
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 14px;
  }

  /* line 94, resources/assets/styles/layouts/_sidebar.scss */

  .sidebar.list .sidebar--content {
    display: none;
  }

  /* line 97, resources/assets/styles/layouts/_sidebar.scss */

  .sidebar.list .sidebar--content.active {
    display: block;
  }
}

/* line 103, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul {
  list-style: none;
  padding: 0;
  float: left;
  width: 100%;
}

/* line 109, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li.current_page_item > a {
  background: white;
}

/* line 112, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li a {
  background: #fbfdfd;
  border-left: 4px solid #0092a7;
  padding: 14px 20px 10px;
  margin-bottom: 8px;
  float: left;
  width: 100%;
  line-height: 1;
  font-size: 15px;
  color: #52565e;
  text-decoration: none;
}

/* line 125, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li a:hover {
  background: white;
}

/* line 133, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li:nth-child(4n + 1) a {
  border-color: #438380;
}

/* line 133, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li:nth-child(4n + 2) a {
  border-color: #254846;
}

/* line 133, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li:nth-child(4n + 3) a {
  border-color: #438cab;
}

/* line 133, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li:nth-child(4n + 4) a {
  border-color: #2b5a6d;
}

/* line 138, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li ul {
  width: calc(100% - 16px);
  margin-left: 16px;
}

/* line 142, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list ul li ul a {
  border-color: #2b5a6d !important;
}

/* line 149, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul {
  width: auto;
}

/* line 151, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul li {
  display: inline-block;
}

/* line 153, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul li a {
  border: none !important;
  background: none;
  padding: 5px;
}

/* line 157, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul li a:hover {
  background: none;
}

/* line 159, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul li a:hover img {
  opacity: 0.8;
}

/* line 163, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.list .sidebar-pf-list ul li a img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* line 174, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.vacatures {
  border-left: 1px solid #e4e4e4;
  padding: 1em 0;
  padding-left: 1.4em;
  display: block;
  font-size: 90%;
}

@media (max-width: 991px) {
  /* line 174, resources/assets/styles/layouts/_sidebar.scss */

  .sidebar.vacatures {
    background: #fff;
    border-radius: 5px;
    padding: 24px 20px;
    border: none;
  }
}

/* line 188, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.vacatures ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 193, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.vacatures ul li {
  margin-bottom: 1em;
}

/* line 196, resources/assets/styles/layouts/_sidebar.scss */

.sidebar.vacatures ul li:last-child {
  margin-bottom: 0;
}

/* line 1, resources/assets/styles/layouts/_footer.scss */

.grecaptcha-badge {
  display: none !important;
}

/* line 5, resources/assets/styles/layouts/_footer.scss */

footer.footer {
  margin-top: 3rem;
}

/* line 11, resources/assets/styles/layouts/_footer.scss */

footer .footer__breadcrumbs {
  padding: 1rem 0;
  border-top: 2px solid #f9f9f9;
  font-size: 0.9rem;
}

/* line 17, resources/assets/styles/layouts/_footer.scss */

footer .footer__main {
  background-color: #f9f9f9;
  padding: 3rem 0;
  font-size: 15px;
}

/* line 22, resources/assets/styles/layouts/_footer.scss */

footer .footer__main strong {
  display: inline-flex;
  margin-bottom: 1rem;
  font-size: 19px;
}

/* line 28, resources/assets/styles/layouts/_footer.scss */

footer .footer__main ul {
  line-height: 1.7;
}

/* line 32, resources/assets/styles/layouts/_footer.scss */

footer .footer__main ul li a {
  color: #52565e;
}

/* line 35, resources/assets/styles/layouts/_footer.scss */

footer .footer__main ul li a:hover {
  text-decoration: none;
  color: #111;
}

@media (min-width: 768px) {
  /* line 44, resources/assets/styles/layouts/_footer.scss */

  footer .footer__main .two-columns {
    column-count: 2;
  }
}

/* line 49, resources/assets/styles/layouts/_footer.scss */

footer .footer__main a.link {
  color: #438380;
  text-decoration: underline;
}

/* line 53, resources/assets/styles/layouts/_footer.scss */

footer .footer__main a.link:hover {
  text-decoration: none;
}

/* line 60, resources/assets/styles/layouts/_footer.scss */

footer .footer__reviews .reviews {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* line 65, resources/assets/styles/layouts/_footer.scss */

footer .footer__reviews .reviews span {
  display: inline-flex;
  color: #438380;
}

/* line 72, resources/assets/styles/layouts/_footer.scss */

footer .footer__logos {
  margin-top: 1rem;
}

/* line 75, resources/assets/styles/layouts/_footer.scss */

footer .footer__logos ul {
  display: flex;
  gap: 1rem;
}

/* line 79, resources/assets/styles/layouts/_footer.scss */

footer .footer__logos ul li {
  display: inline-flex;
}

/* line 82, resources/assets/styles/layouts/_footer.scss */

footer .footer__logos ul li img {
  height: 60px;
  width: auto;
}

/* line 90, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom {
  padding: 1rem 0;
  font-size: 15px;
  color: #52565e;
}

/* line 95, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom .bold {
  font-weight: bold;
}

/* line 98, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom .bold:hover {
  text-decoration: none;
  color: #0069ff;
}

/* line 104, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom ul {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

@media (max-width: 991px) {
  /* line 104, resources/assets/styles/layouts/_footer.scss */

  footer .footer__bottom ul {
    flex-direction: column;
    align-items: center;
  }
}

/* line 115, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom ul li a {
  color: #52565e;
}

/* line 118, resources/assets/styles/layouts/_footer.scss */

footer .footer__bottom ul li a:hover {
  text-decoration: none;
  color: #111;
}

/* line 127, resources/assets/styles/layouts/_footer.scss */

footer .footer__buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 1rem;
}

/* line 1, resources/assets/styles/layouts/_pages.scss */

section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 48px 0;
  background-color: #fff;
}

@media (max-width: 991px) {
  /* line 1, resources/assets/styles/layouts/_pages.scss */

  section {
    padding: 32px 0 24px !important;
  }
}

/* line 13, resources/assets/styles/layouts/_pages.scss */

.wrap {
  margin-top: 90px;
}

@media (max-width: 991px) {
  /* line 13, resources/assets/styles/layouts/_pages.scss */

  .wrap {
    margin-top: 60px;
  }
}

/* line 71, resources/assets/styles/layouts/_pages.scss */

.inschrijven .call-to-action,
.inschrijven .footer__before,
.inschrijven .footer__main {
  display: none !important;
}

/* line 78, resources/assets/styles/layouts/_pages.scss */

.file-pdf {
  background: #e3f1ef;
  padding: 25px 20px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 85%;
}

/* line 85, resources/assets/styles/layouts/_pages.scss */

.file-pdf span {
  color: #52565e;
}

/* line 88, resources/assets/styles/layouts/_pages.scss */

.file-pdf span:before {
  content: '';
  display: block;
  background-image: url(../images/pdf--icon.svg);
  background-size: 50px 50px;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  margin-bottom: 8px;
}

/* line 102, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen {
  padding-top: 0;
  background: #f1f8f7;
}

/* line 106, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a {
  text-decoration: none;
  outline: none;
}

/* line 109, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep {
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

/* line 115, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep .image {
  position: relative;
}

/* line 117, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep .image img {
  width: 100%;
  height: auto;
}

/* line 122, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep .image .title {
  transition: 0.3s;
  width: 100%;
  text-align: left;
  padding: 16px 24px 8px;
  color: #fff;
}

@media (max-width: 575px) {
  /* line 122, resources/assets/styles/layouts/_pages.scss */

  .archive section.doelgroepen a .doelgroep .image .title {
    position: absolute;
    bottom: 0;
    padding: 16px 24px;
  }

  /* line 136, resources/assets/styles/layouts/_pages.scss */

  .archive section.doelgroepen a .doelgroep .image .title span {
    display: none;
  }
}

/* line 141, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep .image .title h3 {
  line-height: 1;
  margin: 0;
}

/* line 145, resources/assets/styles/layouts/_pages.scss */

.archive section.doelgroepen a .doelgroep .image .title span {
  color: white;
  line-height: 1;
  font-size: 90%;
}

/* line 158, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen {
  padding-top: 0;
  background: #f1f8f7;
}

/* line 162, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a {
  text-decoration: none;
  outline: none;
}

/* line 165, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging {
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

/* line 171, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging .image {
  position: relative;
}

/* line 173, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging .image img {
  width: 100%;
  height: auto;
}

/* line 178, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging .image .title {
  transition: 0.3s;
  width: 100%;
  text-align: left;
  padding: 16px 24px 8px;
  color: #fff;
}

@media (max-width: 575px) {
  /* line 178, resources/assets/styles/layouts/_pages.scss */

  .archive section.vestigingen a .vestiging .image .title {
    position: absolute;
    bottom: 0;
    padding: 16px 24px;
  }

  /* line 192, resources/assets/styles/layouts/_pages.scss */

  .archive section.vestigingen a .vestiging .image .title span {
    display: none;
  }
}

/* line 197, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging .image .title h3 {
  line-height: 1;
  margin: 0;
}

/* line 201, resources/assets/styles/layouts/_pages.scss */

.archive section.vestigingen a .vestiging .image .title span {
  color: white;
  line-height: 1;
  font-size: 90%;
}

/* line 214, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden {
  padding-top: 0;
  background: #f1f8f7;
}

/* line 218, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a {
  text-decoration: none;
  outline: none;
}

/* line 221, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid {
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

/* line 227, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid .image {
  position: relative;
}

/* line 229, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid .image img {
  width: 100%;
  height: auto;
}

/* line 234, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid .image .title {
  transition: 0.3s;
  width: 100%;
  text-align: left;
  padding: 24px 16px;
  color: #fff;
}

@media (max-width: 575px) {
  /* line 234, resources/assets/styles/layouts/_pages.scss */

  .archive section.teamleden a .teamlid .image .title {
    position: absolute;
    bottom: 0;
    padding: 16px 24px;
  }

  /* line 246, resources/assets/styles/layouts/_pages.scss */

  .archive section.teamleden a .teamlid .image .title span {
    display: none;
  }
}

/* line 251, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid .image .title h3 {
  line-height: 1;
  margin: 0;
  padding-bottom: 24px;
}

/* line 256, resources/assets/styles/layouts/_pages.scss */

.archive section.teamleden a .teamlid .image .title span {
  color: white;
  line-height: 1;
  font-size: 90%;
}

/* line 269, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten {
  padding-top: 0;
  background: #f1f8f7;
}

/* line 273, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a {
  text-decoration: none;
  outline: none;
  color: #438380;
}

/* line 277, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a:hover {
  color: #254846;
}

/* line 280, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht {
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

/* line 286, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht .image {
  position: relative;
}

/* line 288, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht .image img {
  width: 100%;
  height: auto;
}

/* line 293, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht .image .title {
  transition: 0.3s;
  width: 100%;
  text-align: left;
  padding: 16px 24px;
  color: #fff;
}

@media (max-width: 575px) {
  /* line 293, resources/assets/styles/layouts/_pages.scss */

  .archive section.klachten a .klacht .image .title {
    position: relative;
    bottom: 0;
    padding: 16px 24px;
  }

  /* line 307, resources/assets/styles/layouts/_pages.scss */

  .archive section.klachten a .klacht .image .title span {
    display: none;
  }
}

/* line 312, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht .image .title h3 {
  line-height: 1;
  margin: 0;
  font-size: 15px;
}

/* line 317, resources/assets/styles/layouts/_pages.scss */

.archive section.klachten a .klacht .image .title span {
  color: white;
  line-height: 1;
  font-size: 90%;
}

/* line 331, resources/assets/styles/layouts/_pages.scss */

.page .plaatsen .plaats .title {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  text-align: left;
  padding: 16px 24px;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* line 341, resources/assets/styles/layouts/_pages.scss */

.page .plaatsen .plaats .title a {
  line-height: 1;
  margin: 0;
  font-size: 18px;
  color: #fff;
}

/* line 1, resources/assets/styles/layouts/_tinymce.scss */

body#tinymce {
  margin: 12px !important;
}

/* line 1, resources/assets/styles/pages/_home.scss */

.hero {
  position: relative;
  padding: 110px 0 140px;
}

/* line 5, resources/assets/styles/pages/_home.scss */

.hero--overlay:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(82, 86, 94, 0.4);
}

/* line 15, resources/assets/styles/pages/_home.scss */

.hero--large {
  padding: 0 !important;
}

@media (max-width: 991px) {
  /* line 18, resources/assets/styles/pages/_home.scss */

  .hero--large .hero {
    margin-top: 60px;
    padding: 0 !important;
  }
}

/* line 24, resources/assets/styles/pages/_home.scss */

.hero--large .hero__top {
  background: #eff6f5;
  padding: 80px 0 50px;
}

@media (max-width: 991px) {
  /* line 24, resources/assets/styles/pages/_home.scss */

  .hero--large .hero__top {
    padding: 2rem;
  }
}

/* line 33, resources/assets/styles/pages/_home.scss */

.hero--large .hero__top .title span {
  color: #438380;
}

/* line 39, resources/assets/styles/pages/_home.scss */

.hero--large .hero__bottom {
  height: 340px;
  background-image: url(/app/uploads/2019/12/hero.jpg);
  background-size: cover;
}

@media (max-width: 991px) {
  /* line 39, resources/assets/styles/pages/_home.scss */

  .hero--large .hero__bottom {
    height: 180px;
  }
}

/* line 51, resources/assets/styles/pages/_home.scss */

.hero__title {
  display: flex;
  margin-bottom: 1rem;
}

/* line 55, resources/assets/styles/pages/_home.scss */

.hero__title h2 {
  font-size: 35px;
  color: #fff;
  margin: 0;
}

@media (max-width: 991px) {
  /* line 55, resources/assets/styles/pages/_home.scss */

  .hero__title h2 {
    font-size: 26px;
  }
}

/* line 66, resources/assets/styles/pages/_home.scss */

.hero__content {
  display: flex;
  margin-bottom: 1rem;
  color: #fff;
}

/* line 71, resources/assets/styles/pages/_home.scss */

.hero__content p {
  margin: 0;
}

/* line 76, resources/assets/styles/pages/_home.scss */

.hero__buttons {
  display: flex;
  gap: 1rem;
}

/* line 82, resources/assets/styles/pages/_home.scss */

.navigation-tiles {
  padding: 0;
  background-image: linear-gradient(90deg, #fff 50%, #eff6f5 50%);
  margin-bottom: 2rem;
}

@media (max-width: 991px) {
  /* line 82, resources/assets/styles/pages/_home.scss */

  .navigation-tiles {
    background: #eff6f5;
  }
}

/* line 91, resources/assets/styles/pages/_home.scss */

.navigation-tiles__background {
  background: #eff6f5;
  padding: 48px 0;
  border-radius: 1.5rem;
}

@media (max-width: 991px) {
  /* line 91, resources/assets/styles/pages/_home.scss */

  .navigation-tiles__background {
    padding: 2rem 0;
  }
}

/* line 102, resources/assets/styles/pages/_home.scss */

.navigation-tiles__title h2 {
  font-size: 35px;
  color: #52565e;
}

@media (max-width: 991px) {
  /* line 102, resources/assets/styles/pages/_home.scss */

  .navigation-tiles__title h2 {
    font-size: 1.8rem;
  }
}

/* line 110, resources/assets/styles/pages/_home.scss */

.navigation-tiles__title h2 span {
  color: #438380;
}

@media (max-width: 991px) {
  /* line 101, resources/assets/styles/pages/_home.scss */

  .navigation-tiles__title {
    margin-bottom: 2rem;
  }
}

/* line 120, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles {
  display: flex;
}

/* line 123, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile {
  display: flex;
  padding: 2rem 1.5rem 1.5rem;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 6px;
  gap: 1rem;
}

/* line 131, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile--focus {
  border-color: #438380;
}

/* line 135, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__icon {
  display: flex;
}

/* line 137, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__icon svg {
  color: #438380;
}

/* line 143, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__title h3 {
  font-size: 19px;
  font-weight: bold;
  color: #438380;
  transition: color ease-in-out 200ms;
}

/* line 149, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__title h3:hover {
  text-decoration: none;
  color: #29504f;
}

/* line 155, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__title a {
  transition: color ease-in-out 200ms;
}

/* line 158, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__title a:hover {
  text-decoration: none;
  color: #29504f;
}

/* line 166, resources/assets/styles/pages/_home.scss */

.navigation-tiles__tiles .tile__text p {
  font-size: 0.9rem;
}

@media (max-width: 991px) {
  /* line 174, resources/assets/styles/pages/_home.scss */

  .locations {
    background: #eff6f5;
    padding: 1rem 0 !important;
  }
}

/* line 182, resources/assets/styles/pages/_home.scss */

section .section-background {
  background-color: #eff6f5;
  padding: 4rem 0;
  border-radius: 1.5rem;
}

@media (max-width: 991px) {
  /* line 182, resources/assets/styles/pages/_home.scss */

  section .section-background {
    padding: 2rem 0;
  }
}

/* line 191, resources/assets/styles/pages/_home.scss */

section .section-background--dark {
  background-color: #438380;
  color: #fff;
}

/* line 197, resources/assets/styles/pages/_home.scss */

section .title {
  font-size: 35px;
}

@media (max-width: 991px) {
  /* line 197, resources/assets/styles/pages/_home.scss */

  section .title {
    font-size: 1.8rem;
  }
}

/* line 204, resources/assets/styles/pages/_home.scss */

section .title--primary {
  color: #438380;
}

/* line 208, resources/assets/styles/pages/_home.scss */

section .title--white {
  color: #fff;
}

/* line 213, resources/assets/styles/pages/_home.scss */

section .card {
  border: none;
  border-radius: 0.4rem;
}

/* line 217, resources/assets/styles/pages/_home.scss */

section .card--gray {
  background-color: #f9f9f9;
}

@media (max-width: 991px) {
  /* line 224, resources/assets/styles/pages/_home.scss */

  section .card--white .card__body {
    padding: 1rem 0;
  }
}

/* line 232, resources/assets/styles/pages/_home.scss */

section .card--employee .card__image {
  height: 320px;
  padding-bottom: 1.5rem;
}

@media (max-width: 991px) {
  /* line 232, resources/assets/styles/pages/_home.scss */

  section .card--employee .card__image {
    height: 280px;
  }
}

/* line 240, resources/assets/styles/pages/_home.scss */

section .card--employee .card__image .content {
  z-index: 1;
}

/* line 243, resources/assets/styles/pages/_home.scss */

section .card--employee .card__image .content h3 {
  margin-bottom: 0.25rem;
}

/* line 250, resources/assets/styles/pages/_home.scss */

section .card--employee .card__image .content span {
  color: #fff;
  font-size: 15px;
}

/* line 255, resources/assets/styles/pages/_home.scss */

section .card--employee .card__image .content .read-more {
  color: #fff;
  font-size: 1rem;
  margin-top: 1rem;
  transition: all ease-in-out 200ms;
}

/* line 265, resources/assets/styles/pages/_home.scss */

section .card__image {
  background-image: url(/app/uploads/2019/12/hero.jpg);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: end;
  background-color: #eee;
  height: 140px;
  padding: 1rem 1.5rem;
  border-radius: 0.4rem;
  overflow: hidden;
}

@media (max-width: 991px) {
  /* line 265, resources/assets/styles/pages/_home.scss */

  section .card__image {
    padding: 1rem;
  }
}

/* line 281, resources/assets/styles/pages/_home.scss */

section .card__image a {
  z-index: 1;
}

/* line 284, resources/assets/styles/pages/_home.scss */

section .card__image a:hover {
  text-decoration: none;
}

/* line 289, resources/assets/styles/pages/_home.scss */

section .card__image h3 {
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  z-index: 1;
  margin-bottom: 0;
  transition: all ease-in-out 200ms;
}

/* line 297, resources/assets/styles/pages/_home.scss */

section .card__image h3:hover {
  text-decoration: none;
}

/* line 302, resources/assets/styles/pages/_home.scss */

section .card__image:has(h3)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(100% 100% at 0% 100%, rgba(0, 0, 0, 0.5) 33.33%, rgba(0, 0, 0, 0) 100%);
}

/* line 317, resources/assets/styles/pages/_home.scss */

section .card__body {
  padding: 1.5rem;
}

@media (max-width: 991px) {
  /* line 317, resources/assets/styles/pages/_home.scss */

  section .card__body {
    padding: 1rem;
  }
}

/* line 324, resources/assets/styles/pages/_home.scss */

section .card__body a:has(h3) {
  color: #438380;
  transition: color ease-in-out 200ms;
}

/* line 328, resources/assets/styles/pages/_home.scss */

section .card__body a:has(h3):hover {
  text-decoration: none;
  color: #29504f;
}

/* line 334, resources/assets/styles/pages/_home.scss */

section .card__body h3 {
  font-size: 19px;
  font-weight: bold;
  transition: color ease-in-out 200ms;
}

/* line 339, resources/assets/styles/pages/_home.scss */

section .card__body h3:hover {
  text-decoration: none;
  color: #29504f;
}

/* line 345, resources/assets/styles/pages/_home.scss */

section .card__body p {
  font-size: 15px;
}

/* line 349, resources/assets/styles/pages/_home.scss */

section .card__body address {
  font-size: 15px;
}

/* line 357, resources/assets/styles/pages/_home.scss */

section.content h2,
section.content h3,
section.content h4,
section .content h2,
section .content h3,
section .content h4 {
  font-size: 19px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5em;
}

@media (max-width: 991px) {
  /* line 357, resources/assets/styles/pages/_home.scss */

  section.content h2,
  section.content h3,
  section.content h4,
  section .content h2,
  section .content h3,
  section .content h4 {
    font-size: 1.2rem;
  }
}

/* line 370, resources/assets/styles/pages/_home.scss */

section.content__focus,
section .content__focus {
  background-color: #f1f8f7;
  padding: 36px 34px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-radius: 8px;
}

/* line 377, resources/assets/styles/pages/_home.scss */

section.content__focus h3,
section .content__focus h3 {
  margin-top: 0;
}

/* line 381, resources/assets/styles/pages/_home.scss */

section.content__focus ul,
section .content__focus ul {
  padding-left: 1rem;
}

@media (min-width: 768px) {
  /* line 386, resources/assets/styles/pages/_home.scss */

  section.content__focus .two-columns,
  section .content__focus .two-columns {
    column-count: 2;
  }
}

/* line 392, resources/assets/styles/pages/_home.scss */

section.content .sidebar,
section .content .sidebar {
  background-color: #438380;
  padding: 36px 34px;
  color: #fff;
  border-radius: 8px;
}

/* line 399, resources/assets/styles/pages/_home.scss */

section.content .sidebar__title h3,
section .content .sidebar__title h3 {
  margin-top: 0;
}

/* line 404, resources/assets/styles/pages/_home.scss */

section.content .sidebar__content .read-more,
section .content .sidebar__content .read-more {
  color: #fff;
  transition: all ease-in-out 200ms;
}

