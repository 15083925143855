.faq {
  #accordion {
    .card {
      border: none;
      background: none;
      margin-bottom: .5em;
      padding: 0;
      .card-header {
        background-color:none;
        padding: 0;
        border: none;
        .btn {
          background-color: $base-i;
          display: block;
          width: 100%;
          text-align: left;
          border: none;
          padding: .75rem 1rem;
          // &:after {
          //   font-family: 'Font Awesome 5 Solid';
          //   content: "\f063";

          // }
          svg {
              color: $primary;
              position: absolute;
              font-size: 1.4rem;
              right: 15px;
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
              transition: -webkit-transform .3s;
              transition: transform .3s;
              transition: transform .3s,-webkit-transform .3s;
          }
          &:hover{
            text-decoration: none;
            color: $base;
            background-color: $primary;
            svg {
              color: $base;
            }
          }
          &[aria-expanded=true] {
            text-decoration: none;
            color: $base;
            background-color: $primary;
            svg {
              color: $base;
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
}
