.grecaptcha-badge {
  display: none !important;
}

footer.footer {
  margin-top: 3rem;
}

footer {
  .footer {
    &__breadcrumbs {
      padding: 1rem 0;
      border-top: 2px solid #f9f9f9;
      font-size: 0.9rem;
    }

    &__main {
      background-color: #f9f9f9;
      padding: 3rem 0;
      font-size: 15px;

      strong {
        display: inline-flex;
        margin-bottom: 1rem;
        font-size: 19px;
      }

      ul {
        line-height: 1.7;

        li {
          a {
            color: #52565e;

            &:hover {
              text-decoration: none;
              color: #111;
            }
          }
        }
      }

      @media (min-width: $screen-md-min) {
        .two-columns {
          column-count: 2;
        }
      }

      a.link {
        color: #438380;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__reviews {
      .reviews {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
          display: inline-flex;
          color: #438380;
        }
      }
    }

    &__logos {
      margin-top: 1rem;

      ul {
        display: flex;
        gap: 1rem;

        li {
          display: inline-flex;

          img {
            height: 60px;
            width: auto;
          }
        }
      }
    }

    &__bottom {
      padding: 1rem 0;
      font-size: 15px;
      color: #52565e;

      .bold {
        font-weight: bold;

        &:hover {
          text-decoration: none;
          color: #0069ff;
        }
      }

      ul {
        display: flex;
        gap: 1rem;
        justify-content: center;

        @media (max-width: $screen-md-max) {
          flex-direction: column;
          align-items: center;
        }

        li {
          a {
            color: #52565e;

            &:hover {
              text-decoration: none;
              color: #111;
            }
          }
        }
      }
    }

    &__buttons {
      position: fixed;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 1rem;
    }
  }
}
