.sidebar.advice {
  float: left;
  margin-top: -140px;
  background: $primary;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: darken($base, 8);
  overflow: hidden;

  @media (max-width: $screen-lg-max) {
    margin-top: 1rem;
  }

  .sidebarinner {
    float: left;
    padding: 24px 12px 0;
    text-align: center;

    h3 {
      font-weight: bold;
      text-align: center;
    }
    p {
      font-size: 16px;
      margin-bottom: 0;
    }
    .customer-service {
      float: left;
      width: 100%;
      padding: 14px 0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;

      span {
        float: left;
        width: 100%;
        font-size: 27px;
      }
    }
  }
  a {
    background: lighten($orange, 4);
    float: left;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    color: darken($base, 4);
    padding: 16px 0 15px;
    text-align: center;
    transition: all 0.2s;
    text-decoration: none;

    &:hover {
      background: lighten($orange, 6);
    }
  }
}

.sidebar.list {
  span.read-more {
    user-select: none;
    display: none;
    transition: all ease-in-out 200ms;

    &:after {
      content: '';
      display: block;
      background-image: url(../images/read-more-icon.svg);
      background-size: 22px 22px;
      height: 22px;
      width: 22px;
      float: right;
    }

    &.active {
      background: darken($primary, 4%);
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: $screen-md-max) {
    //display: none;

    span.read-more {
      display: block;
      text-align: left;
      width: 100%;
      margin-bottom: 14px;
    }

    .sidebar--content {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    float: left;
    width: 100%;
    li {
      &.current_page_item > a {
        background: lighten($background, 7);
      }
      a {
        background: lighten($background, 3);
        border-left: 4px solid #0092a7;
        padding: 14px 20px 10px;
        margin-bottom: 8px;
        float: left;
        width: 100%;
        line-height: 1;
        font-size: 15px;
        color: $txtColor;

        text-decoration: none;

        &:hover {
          background: lighten($background, 6);
        }
      }

      $colors: $primary, $green, $blue-light, $blue;

      @for $i from 1 through length($colors) {
        &:nth-child(#{length($colors)}n + #{$i}) a {
          border-color: nth($colors, $i);
        }
      }

      ul {
        width: calc(100% - 16px);
        margin-left: 16px;

        a {
          border-color: $blue !important;
        }
      }
    }
  }
  .sidebar-pf-list {
    ul {
      width: auto;
      li {
        display: inline-block;
        a {
          border: none !important;
          background: none;
          padding: 5px;
          &:hover {
            background: none;
            img {
              opacity: 0.8;
            }
          }
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.sidebar.vacatures {
  border-left: 1px solid #e4e4e4;
  padding: 1em 0;
  padding-left: 1.4em;
  display: block;
  font-size: 90%;

  @media (max-width: $screen-md-max) {
    background: #fff;
    border-radius: 5px;
    padding: 24px 20px;
    border: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
